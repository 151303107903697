import { Alert, Spin } from 'antd';
import { lazy, Suspense, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import api from '~/api';
import { useCache } from '~cache/useCache';
import DataFormControls from '~shared/components/DataForm/DataFormControls';
import useEditMode from '~shared/hooks/useEditMode';
import useLoadData from '~shared/hooks/useLoadData';
import useSubscribe from '~shared/hooks/useSubscribe';
import { useSuggestsMonitor } from '~shared/hooks/useSuggestsMonitor';
import type { Event } from '~shared/utils/ev';
import type { Events } from '~types/events';
import type { Orders } from '~types/orders';
import { useCheckExp, useUser } from '~zustand/userData';

import { dataTest, getConfig, routes } from '../../pageConfig';
import { showIndicator } from '../../Utils/showIndicator';
import { OrderIndicatorSteps } from '../OrderIndicatorSteps';
import OrderDataBlocks from './OrderDataBlocks';

const OrderTabs = lazy(() => import('../OrderTabs'));

interface Props {
	inModal?: boolean;
	orderIdFromProps?: string;
	highlightProductId?: string;
}

export type OrderEventData = {
	users?: string[];
	status?: string;
	estatus?: string;
	version?: number;
};

const OrdersViewDataPage = ({ inModal = false, orderIdFromProps, highlightProductId }: Props) => {
	const [t] = useTranslation();
	const params = useParams<{ order_id: string; store_id: string }>();
	const [formSending, toggleSending] = useState<boolean>(false);
	const [orderEventData, setOrderEventData] = useState<OrderEventData>({});
	const orderId = orderIdFromProps || params.order_id!;
	const user = useUser();
	const expNewDispatch = useCheckExp('exp_new_dispatch');
	const { addData } = useCache({});

	const { data: orderData } = useLoadData(
		async () => {
			// В первый раз грузим сущность через элемент, чтобы показать 403, а в дальнейшем через массив, чтобы включить батчинг запросов
			if (!orderData) {
				return api.orders.load({ order_id: orderId }, { strict: true });
			}

			return api.orders
				.load({ order_id: [orderId] }, { force: true })
				.then((e) => ({ data: { ...e.data, result: e.data.result[0] } }));
		},
		[orderId, orderEventData.version],
		false,
		(data) => {
			addData({
				entityName: 'orders',
				data: [data.result],
			});
		}
	);

	// @ts-expect-error TODO: странные типы
	const order: Orders.Order & Orders.Attr = useMemo(() => {
		const data = { ...(orderData?.result ?? {}), ...orderEventData };
		if (data.attr) {
			return {
				...data,
				...data.attr,
			};
		}
		return data;
	}, [orderData, orderEventData]);

	const suggestsState = useSuggestsMonitor(order);
	const storeId = user.store_id;

	useEditMode(false);

	const subscribeHandler = (data: Event) => {
		data.data
			.map((e) => e as Events.OrderEvent)
			.forEach((entity) => {
				if (entity.order_id === orderId) {
					if (entity.type === 'order') {
						setOrderEventData({
							users: entity.users,
							status: entity.status,
							estatus: entity.estatus,
							version: entity.version,
						});
					}
				}
			});
	};

	useSubscribe(
		{
			key: ['order', 'store', storeId],
			cb: (data) => subscribeHandler(data),
			unSub: true,
			single: false,
			name: 'order',
		},
		[orderId, orderData],
		!orderData
	);

	const showStopListWarning =
		order.type === 'stop_list' &&
		order.status === 'reserving' &&
		order.problems.some((problem) => problem.type === 'product_reserved');

	return (
		<DataFormControls
			editMode={false}
			addMode={false}
			inProgress={formSending}
			disabled={formSending}
			backLink={routes[expNewDispatch ? 'newTable' : 'table'](storeId!)}
			quitEditModeLink={routes[expNewDispatch ? 'newTable' : 'table'](storeId!)}
			submit={() => {}}
			showHeader={!inModal}
			saveBtnTitle={getConfig().btns.add}
			testSelectors={dataTest.form}
			title={{
				...getConfig().formTitles,
				view: getConfig().formTitles.view(order, order.store_id),
			}}
		>
			<Suspense fallback={<Spin />}>
				{showIndicator(order) && <OrderIndicatorSteps order={order} />}
				<OrderDataBlocks order={order} orderId={orderId} inModal={inModal} />

				{showStopListWarning && (
					<Alert
						message={t(
							'Стоп-лист успешно создан и перейдет в обработку после разрезервирования остатка в других документах'
						)}
						type="info"
						showIcon
					/>
				)}
				{order?.order_id && (
					<OrderTabs
						orderId={orderId}
						storeId={storeId!}
						order={order}
						highlightProductId={highlightProductId}
						formSending={formSending}
						toggleSending={toggleSending}
						inModal={inModal}
						suggestsState={suggestsState}
					/>
				)}
			</Suspense>
		</DataFormControls>
	);
};

export default OrdersViewDataPage;
