import dayjs from 'dayjs';

import { dateFormat } from '~constants/date';
import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';

export const timeFormat = 'HH:mm';
export const weekend = ['saturday', 'sunday'];
export const workDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];

export const defaultSearchData = {
	local_date: dayjs().format(dateFormat),
};

export const eventTypes = makeDynamicTranslations({
	check: tDynamic('ЛИ'),
	writeoff_prepare_day: tDynamic('Списание'),
	check_valid_short: tDynamic('КСГ'),
	check_valid_regular: tDynamic('КСГ'),
	visual_control: tDynamic('Виз. контроль'),
	writeoff: tDynamic('Списание'),
});

export const eventScheduleType = makeDynamicTranslations({
	check: '',
	writeoff_prepare_day: tDynamic('Однодневные'),
	check_valid_short: tDynamic('Среднесрок'),
	check_valid_regular: tDynamic('Долгосрок'),
	writeoff: tDynamic('Ежедневно'),
	visual_control: tDynamic('Ежедневно'),
});

export const weekDaysTypes = {
	0: 'sunday',
	1: 'monday',
	2: 'tuesday',
	3: 'wednesday',
	4: 'thursday',
	5: 'friday',
	6: 'saturday',
};

export const INTERVAL_TYPES = ['months', 'days', 'hours', 'minutes', 'seconds'] as const;
export const INTERVAL_TYPES_WITH_TIME = ['hours', 'minutes', 'seconds'] as const;
