import { notification } from '@lavka/ui-kit';
import type { CancelTokenSource } from 'axios';
import axios from 'axios';

import api from '~/api';
import i18n from '~/i18n';
import { useCacheStore } from '~cache/cache';

export const searchProducts = async (
	searchData: string,
	searchField: string,
	cancelTokenSource: CancelTokenSource | undefined,
	productGroups?: string[]
) => {
	if (searchField === 'title' && searchData.length && searchData.length < 4) {
		return;
	}

	const result: { value: string; text: string }[] = [];

	try {
		cancelTokenSource?.cancel();
		cancelTokenSource = axios.CancelToken.source();

		if (searchField === 'barcode') {
			const { data } = await api.search.barcode({ barcode: searchData }, { cancelToken: cancelTokenSource.token });

			(data.result?.products ?? []).forEach((product) => {
				result.push({
					value: product.product_id,
					text: product.title,
				});
			});

			(data.result?.items ?? []).forEach((item) => {
				result.push({
					value: item.item_id,
					text: item.title,
				});
			});
		} else {
			const { data } = await api.products.list(
				{
					product_groups: productGroups?.length ? productGroups : undefined,
					[searchField]: searchData,
					cursor: '',
				},
				{ cancelToken: cancelTokenSource.token }
			);
			useCacheStore.getState().addData({
				entityName: 'products',
				data: data.results,
			});
			(data.results ?? []).forEach((product) => {
				result.push({
					value: product.product_id,
					text: product.title,
				});
			});
		}

		return result;
	} catch {
		notification.error({
			message: i18n.t('Не удалось получить продукты'),
		});
	}
};
