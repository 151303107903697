export const gray = [
	'#ffffff', // 0
	'#fafafa', // 1
	'#f5f5f5', // 2
	'#e8e8e8', // 3
	'#d9d9d9', // 4
	'#bfbfbf', // 5
	'#8c8c8c', // 6
	'#595959', // 7
	'#262626', // 8
	'#000000', // 9
];

export const operationsGrey = '#393939';
export const tableHeaderGrey = '#1d1d1d';
export const headerGrey = '#1f1f1f1';
export const lightGrey = '#8C8C8C';
export const iconOrange = '#FAAD14';
