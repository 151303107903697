import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';

const analyticStatsInfo: Record<
	string,
	{
		key: string;
		label: string;
		// sign - показатель метрики, в какую сторону она улучшается.
		// true = чем больше, тем лучше, false - чем меньше, тем лучше
		sign?: boolean;
		type?: 'time' | 'surge' | 'store_link' | 'cluster_link';
	}
> = makeDynamicTranslations({
	title: { key: 'title', label: tDynamic('Название склада'), type: 'store_link' },
	cluster_title: { key: 'cluster_title', label: tDynamic('Название кластера'), type: 'cluster_link' },
	full_cte: { key: 'full_cte', label: tDynamic('CTE'), type: 'time', sign: false },
	assemble_wait_time: { key: 'assemble_wait_time', label: tDynamic('ожидание сборки'), type: 'time', sign: false },
	assemble_time: { key: 'assemble_time', label: tDynamic('время сборки'), type: 'time', sign: false },
	waiting_for_pickup: { key: 'waiting_for_pickup', label: tDynamic('ожидание выдачи'), type: 'time', sign: false },
	lateness_5_min: { key: 'lateness_5_min', label: tDynamic('опоздания > 5 мин'), sign: false },
	orders_count: { key: 'orders_count', label: tDynamic('заказов'), sign: true },
	oph: { key: 'oph', label: tDynamic('OPH'), sign: true },
	to_client_time: { key: 'to_client_time', label: tDynamic('На пути к клиенту'), type: 'time', sign: false },
	store_surge_level: { key: 'store_surge_level', label: tDynamic('Сурж'), type: 'surge', sign: false },
	pickup_time: { key: 'pickup_time', label: tDynamic('Pick up курьера'), type: 'time', sign: false },
	stowage_speed_time: { key: 'stowage_speed_time', label: tDynamic('Скорость размещения'), type: 'time', sign: false },
});

export default analyticStatsInfo;
