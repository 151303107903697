import { Select } from 'antd';

import analyticStatsInfo from '~constants/analyticStatsInfo';
import capitalize from '~shared/utils/capitalize';

import type { HealthMetric } from '../../healthTypes';

type Props = {
	metric: HealthMetric;
	setMetric: (metric: HealthMetric) => void;
};

const graphKeys = [
	'orders_count',
	'full_cte',
	'assemble_time',
	'assemble_wait_time',
	'waiting_for_pickup',
	'to_client_time',
	'pickup_time',
	'stowage_speed_time',
];

const MetricSelector = ({ metric, setMetric }: Props) => {
	return (
		<Select
			value={metric}
			onSelect={(value) => setMetric(value)}
			data-test="data form health chart metric"
			popupClassName="data-test-select-dropdown-health-chart-metric"
		>
			{graphKeys.map((metric) => {
				const label = capitalize(analyticStatsInfo[metric].label);
				return (
					<Select.Option key={metric} value={metric} data-test={`health chart metric select option ${label}`}>
						{label}
					</Select.Option>
				);
			})}
		</Select>
	);
};

export default MetricSelector;
