// THIS FILE IS AUTOGENERATED

/* eslint-disable max-lines */

import { makeServerTranslations } from '~shared/utils/makeServerTranslations';

export type OrderSource =
	| 'dispatcher'
	| 'tsd'
	| 'eda'
	| '1c'
	| 'integration'
	| 'external'
	| 'original_external'
	| 'woody'
	| 'tristero'
	| 'internal'
	| 'zoho'
	| 'aws'
	| 'autoorder';
export const orderSourceValues = [
	'dispatcher',
	'tsd',
	'eda',
	'1c',
	'integration',
	'external',
	'original_external',
	'woody',
	'tristero',
	'internal',
	'zoho',
	'aws',
	'autoorder',
] as OrderSource[];
export const orderSourceNames = makeServerTranslations(orderSourceValues, 'enums.ORDER_SOURCE');

export type OrderStatus =
	| 'reserving'
	| 'approving'
	| 'request'
	| 'processing'
	| 'delivery'
	| 'complete'
	| 'canceled'
	| 'failed';
export const orderStatusValues = [
	'reserving',
	'approving',
	'request',
	'processing',
	'delivery',
	'complete',
	'canceled',
	'failed',
] as OrderStatus[];
export const orderStatusNames = makeServerTranslations(orderStatusValues, 'enums.ORDER_STATUS');

export type OrderType =
	| 'order'
	| 'sub_order_kitchen'
	| 'sub_order_coffee'
	| 'sub_order_parcels'
	| 'sub_order_external_market_parcels'
	| 'sub_order_units'
	| 'order_retail'
	| 'acceptance'
	| 'acceptance_market'
	| 'assets_writeoff'
	| 'shipment'
	| 'shipment_rollback'
	| 'stowage'
	| 'stowage_market'
	| 'sale_stowage'
	| 'weight_stowage'
	| 'move'
	| 'writeoff'
	| 'check'
	| 'check_product_on_shelf'
	| 'check_more'
	| 'check_final'
	| 'regrading'
	| 'writeoff_prepare_day'
	| 'check_valid_short'
	| 'check_valid_regular'
	| 'visual_control'
	| 'repacking'
	| 'turnoff_starbucks'
	| 'hide_and_show'
	| 'stop_list'
	| 'refund'
	| 'part_refund'
	| 'inventory'
	| 'inventory_check_more'
	| 'inventory_check_product_on_shelf'
	| 'control_check'
	| 'cargo_move'
	| 'cargo_stowage'
	| 'cargo_collect'
	| 'manual_correction'
	| 'collect'
	| 'hand_move'
	| 'kitchen_prod'
	| 'kitchen_provision'
	| 'cash_collect_courier'
	| 'cash_collect_store'
	| 'buy_stocks'
	| 'sell_stocks';
export const orderTypeValues = [
	'order',
	'sub_order_kitchen',
	'sub_order_coffee',
	'sub_order_parcels',
	'sub_order_external_market_parcels',
	'sub_order_units',
	'order_retail',
	'acceptance',
	'acceptance_market',
	'assets_writeoff',
	'shipment',
	'shipment_rollback',
	'stowage',
	'stowage_market',
	'sale_stowage',
	'weight_stowage',
	'move',
	'writeoff',
	'check',
	'check_product_on_shelf',
	'check_more',
	'check_final',
	'regrading',
	'writeoff_prepare_day',
	'check_valid_short',
	'check_valid_regular',
	'visual_control',
	'repacking',
	'turnoff_starbucks',
	'hide_and_show',
	'stop_list',
	'refund',
	'part_refund',
	'inventory',
	'inventory_check_more',
	'inventory_check_product_on_shelf',
	'control_check',
	'cargo_move',
	'cargo_stowage',
	'cargo_collect',
	'manual_correction',
	'collect',
	'hand_move',
	'kitchen_prod',
	'kitchen_provision',
	'cash_collect_courier',
	'cash_collect_store',
	'buy_stocks',
	'sell_stocks',
] as OrderType[];
export const orderTypeNames = makeServerTranslations(orderTypeValues, 'enums.ORDER_TYPE');

export type OrderTargetStatuses = 'complete' | 'canceled' | 'failed';
export const orderTargetStatusesValues = ['complete', 'canceled', 'failed'] as OrderTargetStatuses[];
export const orderTargetStatusesNames = makeServerTranslations(
	orderTargetStatusesValues,
	'enums.ORDER_TARGET_STATUSES'
);

export type OrderCheckValidMode =
	| 'store2trash'
	| 'store2markdown'
	| 'eatToday2markdown'
	| 'markdown2trash'
	| 'store2review'
	| 'review2trash';
export const orderCheckValidModeValues = [
	'store2trash',
	'store2markdown',
	'eatToday2markdown',
	'markdown2trash',
	'store2review',
	'review2trash',
] as OrderCheckValidMode[];
export const orderCheckValidModeNames = makeServerTranslations(
	orderCheckValidModeValues,
	'enums.ORDER_CHECK_VALID_MODE'
);

export type OrderDocumentStatus = 'scan' | 'processing' | 'rescan' | 'done';
export const orderDocumentStatusValues = ['scan', 'processing', 'rescan', 'done'] as OrderDocumentStatus[];
export const orderDocumentStatusNames = makeServerTranslations(
	orderDocumentStatusValues,
	'enums.ORDER_DOCUMENT_STATUS'
);

export type ExtraWayPoints = 'pharmacy' | 'item_pick_up_point';
export const extraWayPointsValues = ['pharmacy', 'item_pick_up_point'] as ExtraWayPoints[];
export const extraWayPointsNames = makeServerTranslations(extraWayPointsValues, 'enums.EXTRA_WAY_POINTS');

export type OrderAttrShelfPickingMethod = 'delete_items' | 'replace_items';
export const orderAttrShelfPickingMethodValues = ['delete_items', 'replace_items'] as OrderAttrShelfPickingMethod[];
export const orderAttrShelfPickingMethodNames = makeServerTranslations(orderAttrShelfPickingMethodValues);

export type InvoiceType = 'bill' | 'payment' | 'refund';
export const invoiceTypeValues = ['bill', 'payment', 'refund'] as InvoiceType[];
export const invoiceTypeNames = makeServerTranslations(invoiceTypeValues, 'enums.INVOICE_TYPE');

export type InvoicePaymentMethodType = 'cash' | 'online';
export const invoicePaymentMethodTypeValues = ['cash', 'online'] as InvoicePaymentMethodType[];
export const invoicePaymentMethodTypeNames = makeServerTranslations(
	invoicePaymentMethodTypeValues,
	'enums.INVOICE_PAYMENT_METHOD_TYPE'
);

export type StopListReasons =
	| 'equipment_broken'
	| 'planed_maintenance'
	| 'no_electricity'
	| 'door_blocked'
	| 'deep_fryer_service'
	| 'chef_is_late'
	| 'no_chef_at_all'
	| 'chef_left_early'
	| 'no_water';
export const stopListReasonsValues = [
	'equipment_broken',
	'planed_maintenance',
	'no_electricity',
	'door_blocked',
	'deep_fryer_service',
	'chef_is_late',
	'no_chef_at_all',
	'chef_left_early',
	'no_water',
] as StopListReasons[];
export const stopListReasonsNames = makeServerTranslations(stopListReasonsValues, 'enums.STOP_LIST_REASONS');

export type OrderConditionsOctopusOrder = 'parent' | 'child';
export const orderConditionsOctopusOrderValues = ['parent', 'child'] as OrderConditionsOctopusOrder[];
export const orderConditionsOctopusOrderNames = makeServerTranslations(orderConditionsOctopusOrderValues);

export type OrderCourierType = 'human' | 'rover';
export const orderCourierTypeValues = ['human', 'rover'] as OrderCourierType[];
export const orderCourierTypeNames = makeServerTranslations(orderCourierTypeValues);

export type OrderDeliveryStatus =
	| 'UNCONFIRMED'
	| 'AWAITING_CARD_PAYMENT'
	| 'CALL_CENTER_CONFIRMED'
	| 'PLACE_CONFIRMED'
	| 'WAITING_ASSIGNMENTS'
	| 'COURIER_ASSIGNED'
	| 'READY_FOR_DELIVERY'
	| 'ORDER_TAKEN'
	| 'ARRIVED_TO_CUSTOMER'
	| 'CUSTOMER_NO_SHOW'
	| 'PICKUP'
	| 'UNKNOWN'
	| 'DELIVERED'
	| 'CANCELLED';
export const orderDeliveryStatusValues = [
	'UNCONFIRMED',
	'AWAITING_CARD_PAYMENT',
	'CALL_CENTER_CONFIRMED',
	'PLACE_CONFIRMED',
	'WAITING_ASSIGNMENTS',
	'COURIER_ASSIGNED',
	'READY_FOR_DELIVERY',
	'ORDER_TAKEN',
	'ARRIVED_TO_CUSTOMER',
	'CUSTOMER_NO_SHOW',
	'PICKUP',
	'UNKNOWN',
	'DELIVERED',
	'CANCELLED',
] as OrderDeliveryStatus[];
export const orderDeliveryStatusNames = makeServerTranslations(
	orderDeliveryStatusValues,
	'enums.ORDER_DELIVERY_STATUS'
);

export type OrderLogSources =
	| 'save'
	| 'ack'
	| 'approve'
	| 'suggest_done'
	| 'suggest_error'
	| 'signal'
	| 'signal_done'
	| 'set_courier'
	| 'set_eda_status'
	| 'set_pause'
	| 'grocery_dispatch'
	| 'reopen'
	| 'cancel'
	| 'cancel_internal'
	| 'cancel_external'
	| 'cancel_user';
export const orderLogSourcesValues = [
	'save',
	'ack',
	'approve',
	'suggest_done',
	'suggest_error',
	'signal',
	'signal_done',
	'set_courier',
	'set_eda_status',
	'set_pause',
	'grocery_dispatch',
	'reopen',
	'cancel',
	'cancel_internal',
	'cancel_external',
	'cancel_user',
] as OrderLogSources[];
export const orderLogSourcesNames = makeServerTranslations(orderLogSourcesValues, 'enums.ORDER_LOG_SOURCES');

export type LogRecordVarsAction = 'assign' | 'unassign';
export const logRecordVarsActionValues = ['assign', 'unassign'] as LogRecordVarsAction[];
export const logRecordVarsActionNames = makeServerTranslations(logRecordVarsActionValues);

export type LogRecordVarsCode = 'OK' | 'FAIL';
export const logRecordVarsCodeValues = ['OK', 'FAIL'] as LogRecordVarsCode[];
export const logRecordVarsCodeNames = makeServerTranslations(logRecordVarsCodeValues);
