import { mobileMaxWidth } from '~constants/breakPoints';
import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	statBlock: {
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		blockSize: 90,
		paddingBlock: 8,
		paddingInline: 8,
		minInlineSize: 100,
		borderBlockEnd: '1px solid rgba(227, 227, 224, .5)',
		borderInlineEnd: '1px solid rgba(227, 227, 224, .5)',

		[`@media (min-width:${mobileMaxWidth}px)`]: {
			minInlineSize: 100,
			maxInlineSize: 200,
		},

		['@media (min-width:1280px)']: {
			'&:not(:last-child)': {
				border: 0,
				borderInlineEnd: '1px solid rgba(227, 227, 224, .5)',
			},
		},
	},
	statTitle: {
		fontSize: theme.uiKit.semantic.typography.caption2,
		lineHeight: theme.uiKit.semantic.typography.lineHeightCaption2,
		color: theme.uiKit.semantic.textMinor,
	},
	statContent: {
		display: 'flex',
		flexDirection: 'column',
		rowGap: 2,
		fontWeight: 500,
	},
	statDiff: {
		fontSize: theme.uiKit.semantic.typography.caption2,
		lineHeight: theme.uiKit.semantic.typography.lineHeightCaption2,
	},
	statSign: {
		marginInlineEnd: 5,
	},
	redText: {
		color: theme.text.statusRed,
	},
	greenText: {
		color: theme.text.statusLime,
	},
	statValue: {
		fontSize: theme.uiKit.semantic.typography.caption1,
		lineHeight: theme.uiKit.semantic.typography.lineHeightCaption1,
	},
	loader: {
		'*': { marginBlockEnd: 0 },
	},
	metricInRed: {
		backgroundColor: theme.bgColor.statusRed,
	},
	statInfo: {
		display: 'flex',
		flexDirection: 'column',
	},
	goalText: {
		fontSize: '0.9em',
		color: theme.text.light,
		lineHeight: 0.9,
	},
	goalMetricInRed: {
		color: theme.text.error,
	},
}));
