import axios from 'axios';

import type { Dxgy } from '~types/dxgy';

const X_WMS_PROXYPATH = 'X-WMS-ProxyPath';
const X_WMS_CLUSTER_ID = 'X-WMS-Cluster-Id';
const DXGY_PROXY = '/api/admin/salaries/dxgy/proxy';
const DXGY_HOST = '/admin/grocery-dxgy-registry';

export default {
	list: (data: Dxgy.ListRequest) =>
		axios.post<Dxgy.ListResponse>(DXGY_PROXY, data, {
			headers: {
				[X_WMS_PROXYPATH]: `${DXGY_HOST}/goals/v1/list`,
				[X_WMS_CLUSTER_ID]: data.cluster_id,
			},
		}),
	draftLoad: <T extends Dxgy.CommonDraft | Dxgy.PersonalDraft>({ cluster_id, ...data }: Dxgy.LoadDraftRequest) =>
		axios.post<Dxgy.Draft<T>>(DXGY_PROXY, data, {
			headers: {
				[X_WMS_PROXYPATH]: `${DXGY_HOST}/drafts/v1/view`,
				[X_WMS_CLUSTER_ID]: cluster_id,
			},
		}),
	goalLoad: <T extends Dxgy.CommonGoal | Dxgy.PersonalGoal>({ cluster_id, ...data }: Dxgy.LoadGoalRequest) =>
		axios.post<Dxgy.GoalBack<T>>(DXGY_PROXY, data, {
			headers: {
				[X_WMS_PROXYPATH]: `${DXGY_HOST}/goals/v1/view`,
				[X_WMS_CLUSTER_ID]: cluster_id,
			},
		}),
	save: <T extends Dxgy.CommonDraft | Dxgy.PersonalDraft | Dxgy.EditGoal>(dxgy: Dxgy.SaveRequest<T>) =>
		axios.post(DXGY_PROXY, dxgy, {
			headers: {
				[X_WMS_PROXYPATH]: `${DXGY_HOST}/drafts/v1/create`,
				[X_WMS_CLUSTER_ID]: dxgy.cluster_id,
			},
		}),
	edit: <T extends Dxgy.CommonDraft | Dxgy.PersonalDraft>(dxgy: Dxgy.SaveRequest<T>) =>
		axios.post(DXGY_PROXY, dxgy, {
			headers: {
				[X_WMS_PROXYPATH]: `${DXGY_HOST}/drafts/v1/edit`,
				[X_WMS_CLUSTER_ID]: dxgy.cluster_id,
			},
		}),
	changeStatus: ({ cluster_id, ...dxgy }: Dxgy.ChangeStatusRequest) =>
		axios.post(DXGY_PROXY, dxgy, {
			headers: {
				[X_WMS_PROXYPATH]: `${DXGY_HOST}/drafts/v1/change-status`,
				[X_WMS_CLUSTER_ID]: cluster_id,
			},
		}),
	validateYtGoal: ({ cluster_id, yt_table }: Dxgy.ValidateYtGoal) =>
		axios.get<Dxgy.TableData>(DXGY_PROXY, {
			headers: {
				[X_WMS_PROXYPATH]: `${DXGY_HOST}/goals/v1/validate-yt-goals?yt_table=${yt_table}&cluster_id=${cluster_id}`,
				[X_WMS_CLUSTER_ID]: cluster_id,
			},
		}),
};
