import { notification } from '@lavka/ui-kit';
import { Spin } from 'antd';
import type { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import api from '~/api';
import { useCache } from '~cache/useCache';
import ModalComponent from '~shared/components/ModalComponent';
import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';
import type { Orders } from '~types/orders';
import { useOrders } from '~zustand/ordersData';

import { useStyles } from '../styles';
import AssignInformer from './AssignInformer';
import type { UnassignRequestData } from './context';
import { Context } from './context';
import UnassignCourier from './UnassignCourier';

interface Props {
	orderId: Orders.Order['order_id'];
	closeModal: () => void;
}

const errorsCodeMessages = makeDynamicTranslations({
	ER_EXTERNAL_SERVICE: tDynamic('Невозможно снять курьера с заказа'),
	ER_ALREADY_PROCESSING: tDynamic('Уже назначено'),
});

const UnassignCourierModal = ({ orderId, closeModal }: Props) => {
	const allowedStatuses = [424, 429];
	const [t] = useTranslation();
	const { cx, classes } = useStyles();
	const [requestData, setRequestData] = useState<Omit<UnassignRequestData, 'order_id'>>({});
	const [sending, toggleSending] = useState<boolean>(false);
	const [assignDisable, toggleAssignDisable] = useState<boolean>(false);

	const cache = useCache({
		orders: orderId,
	});
	const { orders } = useOrders();
	const order = orders[orderId] ?? cache.orders[orderId];

	useEffect(() => {
		if (order?.vars?.manual_dispatch_last_time) {
			const interval = setInterval(() => {
				const disabled = dayjs().diff(order.vars?.manual_dispatch_last_time, 'minute') < 2;
				toggleAssignDisable(disabled);
				if (!disabled) {
					clearInterval(interval);
				}
			});
			return () => {
				clearInterval(interval);
			};
		} else if (assignDisable) {
			toggleAssignDisable(false);
		}
	}, [order?.vars?.manual_dispatch_last_time]);

	const errorMessages = {
		'grocery-dispatch service does not support order': t('Функция недоступна для выбранного заказа'),
		'grocery-dispatch service failed to unassign courier': t('Невозможно снять курьера с заказа'),
		'grocery-dispatch service is unavailable': t('Не удалось отправить запрос на снятие курьера'),
	};

	const sendSignal = useCallback(async () => {
		if (sending) return;
		toggleSending(true);
		try {
			await api.orders.unassign_courier(
				{
					...requestData,
					order_id: order!.order_id,
				},
				{
					disableDefaultNotification: allowedStatuses,
					disableExternalLog: allowedStatuses,
				}
			);
			notification.success({
				message: t('Запрос на назначение курьера отправлен'),
			});
			closeModal();
		} catch (error) {
			const { code, message } = (error as AxiosError)['data'] ?? {};
			notification.error({
				message: allowedStatuses.includes(error.status) ? errorsCodeMessages[code] : errorMessages[message || ''],
			});
		} finally {
			toggleSending(false);
		}
	}, [order, requestData]);

	const onCancel = () => {
		closeModal();
		setRequestData({});
	};

	const setRequestField = (data: Record<string, string | boolean | undefined>) => {
		if (data.hasOwnProperty('disable_batching')) {
			setRequestData({
				...requestData,
				...data,
			});
		} else {
			setRequestData({
				...requestData,
				taxi_only: false,
				courier_id: undefined,
				batch_to_order_id: undefined,
				...data,
			});
		}
	};

	const contextValue = useMemo(
		() => ({
			order,
			requestData,
			setRequestData,
			setRequestField,
		}),
		[order, requestData, setRequestData, setRequestField]
	);

	if (!order) {
		return null;
	}

	return (
		<Context.Provider value={contextValue}>
			<ModalComponent
				width={920}
				open={!!order}
				onCancel={onCancel}
				destroyOnClose
				okText={t('Подтвердить')}
				okButtonProps={{
					disabled: sending || assignDisable,
				}}
				cancelText={t('Отмена')}
				onOk={sendSignal}
				title={
					<AssignInformer
						lastTime={order?.vars?.manual_dispatch_last_time}
						lastTarget={order?.vars?.manual_dispatch_last_target}
					/>
				}
				className={cx(`data-test-unassign-courier-modal-${order ? 'visible' : 'hidden'}`, classes.unassignModal)}
			>
				{order ? <UnassignCourier order={order} /> : <Spin />}
			</ModalComponent>
		</Context.Provider>
	);
};

export default UnassignCourierModal;
