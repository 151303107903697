import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const GOOGLE_MAPS_API_KEY = 'AIzaSyDq8qgS9pzlHBT7jlceuy0uNFHKIlPDF9w';

export default function (skip = false) {
	const [, i18n] = useTranslation();
	const [isReady, setReady] = useState<boolean>(false);
	const script = useRef<HTMLScriptElement | null>(null);

	useEffect(() => {
		window.initGoogleMap = () => setReady(true);
	}, []);

	useEffect(() => {
		if (skip) {
			return;
		}

		setReady(false);
		window.google = undefined;

		if (script.current) {
			document.body.removeChild(script.current);
		}

		script.current = document.createElement('script');
		script.current.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&language=${i18n.language}&libraries=drawing,geometry&callback=initGoogleMap`;
		script.current.async = true;
		script.current.crossOrigin = 'anonymous';
		document.body.appendChild(script.current);
	}, [skip, i18n.language]);

	return isReady;
}
