import { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

/**
 * Хук для force ререндера компонента.
 */
export default function useRerender() {
	const [rerenderKey, setRerenderKey] = useState<string>(() => uuidv4());

	const updateRerenderKey = useCallback(() => {
		setRerenderKey(uuidv4());
	}, []);

	return {
		rerenderKey,
		updateRerenderKey,
	};
}
