import { useState } from 'react';

import { useAsyncEffect } from '~shared/hooks/useAsyncEffect';
import { isObject } from '~shared/utils/isObject';

import { type EntityMap, type EntityName } from './apiLoadProperties';
import type { Entity, Id, UseCacheEntityState, UseCacheStore } from './cache';
import { useCacheStore } from './cache';

export type CacheParams = {
	[K in keyof EntityMap]?: Entity<EntityMap[K]> | Id | Id[];
};

type UseCache<T extends CacheParams> = {
	[K in keyof EntityMap & keyof T]: Record<
		string,
		T[K] extends {
			_fields: Readonly<(infer F extends keyof EntityMap[K])[]>;
		}
			? Pick<EntityMap[K], F> | undefined
			: EntityMap[K] | undefined
	> & { loading: boolean };
} & { loading: boolean; loaded: boolean };

/**
 * @description
 * Хук предназначен для загрузки сущностей с бэка, в том числе частичной.
 *
 * Из хука возвращается объект только с теми видами сущностей, которые были указаны при его инициализации,
 * например если в передаваемом объекте указали stores, вернутся все запрошенные stores (вне зависимости от переданных id)
 *
 * В id[] допустимы дубликаты и nullable-значения так как внутри cache есть обработка
 *
 * Пример частичной загрузки (желательное использование):
 * 	useCache({
 *		[entityName]: {
 *	 		ids: id | id[],
 *			_fields: (keyof Entity)[],
 *  	},
 * 		...
 * 	});
 *
 * Пример полной загрузки (нежелательное использование):
 * 	useCache({
 *		[entityName]: id | id[],
 * 		...
 * 	});
 *
 * Пример получения сущностей из кэша без загрузки их с бэка:
 * 	useCache({
 *		[entityName]: [],
 * 		...
 * 	});
 *
 * Если сущности запрашиваются вне кэша (например через useLoadData или ручки /list, /load),
 * и потом они могут пригодиться, полученные данные рекомендуется добавить в кэш
 * с помощью cache.addData во избежание повторных запросов в дальнейшем.
 * Пример:
 * 	const { data } = await api.stores.list(searchData);
 * 	cache.addData({
 * 		entityName: 'stores',
 * 		data: data.results,
 * 	});
 *
 */

export const useCache = <T extends CacheParams & { [K in keyof T as Exclude<K, keyof EntityMap>]: never }>(
	params: T
): UseCache<T> & {
	addData: UseCacheStore['addData'];
} => {
	const [loaded, setLoaded] = useState(false);
	const refreshData = useCacheStore((state) => state.refreshData);
	const genericParams = Object.entries(params).reduce(
		(acc, [keyId, ids]) => {
			// @ts-ignore
			acc[keyId] = isObject(ids) && !Array.isArray(ids) ? ids : { ids };

			return acc;
		},
		{} as Record<EntityName, Entity<EntityMap[EntityName]>>
	);

	useAsyncEffect(async () => {
		try {
			setLoaded(false);

			await Promise.allSettled(
				Object.entries(genericParams).map(([keyId, entity]) => {
					// @ts-expect-error
					return refreshData(keyId as EntityName, entity);
				})
			);

			setLoaded(true);
		} catch {}
	}, [JSON.stringify(params)]);

	const entities = useCacheStore(
		(state) => {
			const result = Object.entries(genericParams).reduce(
				(acc, [keyId, entity]) => {
					// 1. Оставляем только те поля из state, которые указаны в params, и выкидываем id, для которых данные загружены частично
					// 2. Делаем преобразование
					//
					// До:
					// stores: {
					//     data: {
					//         [id]: Stores.Store,
					//     },
					//     fields: Set<string> | null,
					//     loading: boolean,
					//     loaded: boolean,
					// },
					//
					// После:
					// stores: {
					//     [id]: Stores.Store,
					//     loading: boolean,
					//     loaded: boolean,
					// },
					let iterable: Id | Id[] = entity.ids;

					// @ts-ignore
					const entityState: UseCacheEntityState<T> = state[keyId];

					const entries: Record<string, T> = {};
					// @ts-ignore
					acc[keyId] = entries;

					if (!iterable) {
						return acc;
					}

					if (iterable.length == 0) {
						iterable = Object.keys(entityState?.data ?? {});
					}

					if (!Array.isArray(iterable)) {
						iterable = [iterable];
					}

					iterable.forEach((entryId: Id) => {
						if (!entryId || !entityState?.fields) {
							return;
						}
						const fields = entityState.fields[entryId];

						// Полные сущности отдаём всегда, а неполные - только при соответствии условиям
						if (fields === null) {
							entries[entryId] = entityState.data[entryId];
						} else if (fields instanceof Set && entity._fields?.every((field) => fields.has(field))) {
							entries[entryId] = entityState.data[entryId];
						}
					});

					Object.defineProperty(acc[keyId as keyof EntityMap & keyof T], 'loading', {
						value: entityState?.loadingCounter > 0,
						enumerable: false,
					});

					return acc;
				},
				{
					addData: state.addData,
				} as UseCache<T> & {
					addData: UseCacheStore['addData'];
				}
			);

			result.loading = Object.values(result).some(
				(resultEntity) => typeof resultEntity === 'object' && resultEntity.loading
			);

			return result;
		},
		compareTwoNestings<T>
	);

	return {
		...entities,
		loaded,
	};
};

function compareTwoNestings<T extends CacheParams>(
	object1: unknown,
	object2: unknown,
	isSecondNesting = false
): boolean {
	if (!isObject(object1) || !isObject(object2)) {
		return object1 === object2;
	}

	if (Object.keys(object1).length !== Object.keys(object2).length) {
		return false;
	}

	return Object.keys(object1).every((key) => {
		const value1 = object1[key as keyof typeof object1];
		const value2 = object2[key as keyof typeof object2];

		if (value1 === value2) {
			return true;
		}

		return !isSecondNesting && compareTwoNestings<T>(value1, value2, true);
	});
}
