import { notification } from '@lavka/ui-kit';
import dayjs from 'dayjs';

import api from '~/api';
import i18n from '~/i18n';
import { getField } from '~constants/dataPageFields/fieldHelper';
import type { CustomField, SectionConfig } from '~constants/dataPageFields/types';
import { errorsCode } from '~constants/errorsCode';
import type { OrderType } from '~server-types/doc/api/models/order';
import PriceForAmountField from '~shared/components/Fields/PriceForAmountField';
import calculateDistance from '~shared/utils/calculateDistance';
import { getAcceptanceApproveTime } from '~shared/utils/datesUtils';
import { isObjectEmpty } from '~shared/utils/isObjectEmpty';
import type { ResponseDetailsError, ResponseError } from '~types/common';
import type { Orders } from '~types/orders';
import type { Products } from '~types/products';

import type { FormattedProduct, OrderFormValues, ReportAdditionalTypes } from './types';

export const formatFields = (fields: Record<string, SectionConfig[]>, orderType: OrderType) => {
	const formattedFields = fields[orderType];
	if (['acceptance', 'order', 'kitchen_prod', 'weight_stowage', 'sale_stowage'].includes(orderType)) {
		let field = getField(formattedFields, '', 'price');
		if (field) {
			field = field as CustomField;
			field.hidden = false;
			field.canEdit = true;
			field.element = <PriceForAmountField key="priceForAmount" />;
		}
	}
	return formattedFields;
};

export const isUnitOrWeight = (product: any) => {
	if (product) {
		if (product.type_accounting === 'weight' && product.parent_id === null) {
			return 'weight';
		} else {
			return 'unit';
		}
	}
	return null;
};

export const isDiffInResult = ({
	acceptance_count,
	acceptance_weight,
	acceptance_result_count,
	acceptance_result_weight,
	stowage_result_count,
	stowage_result_weight,
}: any) => {
	if (acceptance_count || acceptance_result_count || stowage_result_count) {
		return !(acceptance_count === acceptance_result_count && acceptance_result_count === stowage_result_count);
	}
	if (acceptance_weight || acceptance_result_weight || stowage_result_weight) {
		return !(acceptance_weight === acceptance_result_weight && acceptance_result_weight === stowage_result_weight);
	}
	return false;
};

export const getStowagesToFetch = (stowageId: string | string[] | undefined): string[] | null => {
	if (!stowageId) return null;
	const stowagesToFetch = Array.isArray(stowageId) ? stowageId : [stowageId];
	return stowagesToFetch.length ? stowagesToFetch : null;
};

export const getResultWithStowages = async function (order: Orders.Order) {
	let stowageOrderProducts: Orders.OrderItem[] = [];
	const stowagesToFetch = getStowagesToFetch(order?.vars?.stowage_id);
	if (stowagesToFetch) {
		const { data } = await api.orders.load({
			order_id: stowagesToFetch,
		});
		stowageOrderProducts = data.result.flatMap((order) => order.required);
	}
	const productsObj: Record<string, Orders.OrderItem & ReportAdditionalTypes> = {};
	stowageOrderProducts.forEach((product) => {
		if (productsObj[product.product_id]) {
			productsObj[product.product_id] = {
				...productsObj[product.product_id],
				stowage_result_count:
					product.result_count !== undefined
						? (productsObj[product.product_id].result_count || 0) + (product.result_count || 0)
						: undefined,
				stowage_result_weight:
					product.result_weight !== undefined
						? (productsObj[product.product_id].result_weight || 0) + product.result_weight
						: undefined,
			};
		} else {
			productsObj[product.product_id] = {
				...product,
				stowage_result_count: product.result_count,
				stowage_result_weight: product.result_weight,
				acceptance_count: '—',
				acceptance_weight: '—',
				acceptance_result_count: '—',
				acceptance_result_weight: '—',
			};
		}
	});
	// на данном этапе у нас в объекте продукты из stowage
	order.required.forEach((product) => {
		if (productsObj[product.product_id]) {
			productsObj[product.product_id] = {
				...productsObj[product.product_id],
				acceptance_count: product.count,
				acceptance_weight: product.weight,
				acceptance_result_count: product.result_count,
				acceptance_result_weight: product.result_weight,
			};
		} else {
			productsObj[product.product_id] = {
				...product,
				acceptance_count: product.count,
				acceptance_weight: product.weight,
				acceptance_result_count: product.result_count,
				acceptance_result_weight: product.result_weight,
				stowage_result_count: '—',
				stowage_result_weight: '—',
			};
		}
	});
	// в объект добавили продукты из acceptance
	return Object.values(productsObj);
};

export const addressWithDistance = (address: Orders.ClientAddress, storeCoords: any) => {
	const distance =
		address &&
		address.lat &&
		address.lon &&
		storeCoords &&
		calculateDistance([address.lat, address.lon], [storeCoords[1], storeCoords[0]]);
	return address && typeof address === 'object'
		? `${distance ? `(~${distance.toFixed(2)} ${i18n.t('км')})` : '(-)'} ${address.fullname}` || '—'
		: '—';
};

const nonEmpty = (value: string | number, valueToShow: string | number | undefined) =>
	Number(value) !== 0 && valueToShow !== '—';

export const isDivergent = (product: FormattedProduct) =>
	nonEmpty(product.delta, product.delta_to_show) ||
	nonEmpty(product.result_delta, product.result_delta_to_show) ||
	!!product.deviation ||
	nonEmpty(product.trash, product.trash_to_show);

export const checkAcceptanceApprovalTime = (childrenDone: string | undefined, tz: string | undefined) => {
	if (!childrenDone) {
		return false;
	}
	// 16:00 следующего дня от all_children_done
	const nextDayControlPoint = getAcceptanceApproveTime(childrenDone, tz);

	// директор должен подтвердить приемку до этого времени или она подтвердится автоматически

	const itIsNotTooLate = nextDayControlPoint ? dayjs().isBefore(nextDayControlPoint) : false;

	return itIsNotTooLate;
};

const getMessagesFromProductsWithoutComponents = async (
	errors: ResponseDetailsError[],
	productsWithoutComponents: string[]
) => {
	const errorMessages: { message: string }[] = [];

	try {
		const response = await api.products.load({ product_id: productsWithoutComponents });

		const arr = response.data.result.map(({ title }, index) => {
			const current = errors[index];
			const message =
				current.code === 'ER_PRODUCT_WITHOUT_COMPONENTS'
					? 'ER_PRODUCT_WITHOUT_COMPONENTS'
					: (current.code && errorsCode[current.code]) || current.message || current.code || '';

			return { message, title };
		});

		arr.forEach(({ message, title }) => {
			const errorMessage =
				message === 'ER_PRODUCT_WITHOUT_COMPONENTS'
					? i18n.t('Продукт "{{productName}}" должен содержать рецепт', { productName: title })
					: message;
			errorMessages.push({ message: errorMessage });
		});
	} catch ({ data }) {
		notification.error({
			message: errorsCode[data.code] || data.message || data.code,
		});
	}

	return errorMessages;
};

export const getErrorMessages = async (error: ResponseError) => {
	if (error.code === 'ER_BAD_REQUIRED' && error.message === 'No permit to move to markdown') {
		return [{ message: i18n.t('Запрещено создавать ручное перемещение на полку распродажа') }];
	}

	if (error.code === 'ER_STORE_MODE') {
		return [
			{
				message: errorsCode[error.code],
				description: i18n.t(
					'Часть операций недоступна в период завершения полной инвентаризации. Это может занять до 15 минут.'
				),
			},
		];
	}

	if (Array.isArray(error.details?.errors)) {
		const productsWithoutComponents = error
			.details!.errors?.map(({ product_id }) => product_id)
			.filter((product_id) => !!product_id);

		if (productsWithoutComponents.length) {
			// @ts-expect-error
			return await getMessagesFromProductsWithoutComponents(error.details.errors, productsWithoutComponents);
		}
	}

	return [{ message: errorsCode[error.code] || error.message || error.code }];
};

export const getWeightString = (weight: number) => {
	if (weight === 0) {
		return 0;
	}

	const weightAmount = Number((weight / 1000).toFixed(3));
	return i18n.t('{{weight}} кг', { weight: String(weightAmount).replace('.', ',') });
};

export const prepareValuesForSending = (
	values: OrderFormValues,
	cacheProducts: Record<string, Products.Product | undefined>
) => {
	const formattedValues = { ...values };
	delete formattedValues.result_count;

	const preparedAttr = {};

	for (const prop in formattedValues.attr) {
		if (formattedValues.attr[prop]) {
			preparedAttr[prop] = formattedValues.attr[prop];
		}
	}

	if (!isObjectEmpty(preparedAttr)) {
		formattedValues.attr = preparedAttr;
	} else {
		delete formattedValues.attr;
	}

	if (values.type === 'check_more') {
		const shelves: string[] = [];
		values.required?.forEach((value: any) => {
			if (value.shelf_id) {
				shelves.push(value.shelf_id);
			}
		});
		if (shelves.length > 0) {
			formattedValues.shelves = shelves;
		}
		delete formattedValues.required;
	}
	if (values.type === 'stop_list') {
		values.required?.forEach((value: any) => {
			if (!value.product_id) {
				value.product_id = null;
			}
			if (!value.shelf_id) {
				value.shelf_id = null;
			}
		});
	}
	if (values.type === 'writeoff') {
		delete formattedValues.required;
	}
	if (values.type === 'acceptance') {
		formattedValues.required?.forEach((value: Orders.OrderItem) => {
			const product = cacheProducts[value.product_id];
			const measureType = isUnitOrWeight(product);

			if (measureType === 'weight') {
				value.count = undefined;
			} else if (measureType === 'unit') {
				value.weight = undefined;
			}
		});
	}
	if (values.type === 'stop_list' && values.required?.some((item: any) => !!item.shelf_id)) {
		formattedValues.creation_reason = { comment: formattedValues.creation_reason as string };
	}

	return formattedValues;
};
