import { gray } from '~styles/colors';

import { createDiv } from '../utils';
import type { EdaStatusColor, GoogleInfoWindows, Refresher } from './GoogleOrdersMap';

const VIEW_DISTANCE = 4000;
declare const google: any;

interface Props {
	map: google.maps.Map;
	infoWindows: GoogleInfoWindows;
	coords: {
		lat: number;
		lng: number;
	};
	classes: {
		markContainerClass: string;
		markClass: string;
	};
	docNumber: string;
	orderId: string;
	getEventListeners: (orderId: string) => () => void;
}

const createGoogleBalloon = ({
	map,
	infoWindows,
	coords: { lat, lng },
	classes: { markContainerClass, markClass },
	docNumber,
	orderId,
	getEventListeners,
}: Props) => {
	class Balloon extends google.maps.OverlayView {
		position: google.maps.LatLng;
		triangle: HTMLDivElement;
		rectangle: HTMLDivElement;
		mark: HTMLDivElement;
		markContainer: HTMLDivElement;
		marker: google.maps.Marker;
		docNumber: string;
		color: EdaStatusColor = 'gray';
		infoWindow: google.maps.InfoWindow;
		infoWindowContent = '';
		clusterInfoWindowRefresher: Refresher | null = null;

		constructor() {
			super();
			this.position = new google.maps.LatLng(lat, lng);
			this.docNumber = docNumber;
			this.orderId = orderId;

			this.triangle = createDiv({
				classNames: ['triangle'],
			});

			this.rectangle = createDiv({
				classNames: ['rectangle'],
			});

			this.mark = createDiv({
				classNames: [markClass],
				children: [this.triangle, this.rectangle],
			});

			this.markContainer = createDiv({
				classNames: [markContainerClass],
				children: [this.mark],
			});

			Balloon.preventMapHitsAndGesturesFrom(this.markContainer);

			this.marker = new google.maps.Marker({
				position: { lat, lng },
				options: { opacity: 0 },
				map,
			});

			const openEventListener = getEventListeners(orderId);
			this.infoWindow = new google.maps.InfoWindow({ headerDisabled: true });
			this.infoWindow.addListener('domready', () => {
				openEventListener();
			});
			infoWindows.add(this.infoWindow);
			this.addEventListenerOnMark();
		}

		addEventListenerOnMark() {
			this.mark.addEventListener('click', () => {
				infoWindows.closeAll();

				this.infoWindow.open({
					anchor: this as unknown as google.maps.Marker,
				});
			});
		}

		setTitle(title: string) {
			this.rectangle.textContent = title;
		}

		setColor(color: EdaStatusColor) {
			this.color = color;
			this.triangle.style.backgroundColor = color;
			this.rectangle.style.borderColor = color;
		}

		setTheme(isLight: boolean) {
			this.rectangle.style.backgroundColor = isLight ? gray[0] : gray[8];
		}

		setClusterInfoWindowRefresher(refresher: Refresher) {
			this.refreshClusterInfoWindow = refresher;
		}

		setInfoWindowContent(content: string) {
			this.infoWindowContent = content;
			this.infoWindow.setContent(content);
			this.refreshClusterInfoWindow && this.refreshClusterInfoWindow();
		}

		onAdd() {
			this.getPanes()!.floatPane.append(this.markContainer);
		}

		onRemove() {
			if (this.markContainer.parentElement) {
				this.markContainer.parentElement.removeChild(this.markContainer);
			}
		}

		draw() {
			const divPosition = this.getProjection().fromLatLngToDivPixel(this.position)!;
			// Hide the popup when it is far out of view
			const display =
				Math.abs(divPosition.x) < VIEW_DISTANCE && Math.abs(divPosition.y) < VIEW_DISTANCE ? 'block' : 'none';

			if (display === 'block') {
				this.markContainer.style.left = `${divPosition.x}px`;
				this.markContainer.style.top = `${divPosition.y}px`;
			}

			if (this.markContainer.style.display !== display) {
				this.markContainer.style.display = display;
			}
		}

		getPosition() {
			return this.marker.getPosition();
		}

		getVisible() {
			return this.marker.getVisible();
		}
	}

	return new Balloon();
};

export default createGoogleBalloon;
