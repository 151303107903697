import dayjs from 'dayjs';

import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';
import type { FinanceWallets, FinanceWalletsRequests } from '~types/financeWallets';
import { useUserConstants } from '~zustand/userData';

export const OPERATION_TYPES = ['put', 'get'].join(',');

export function isLimitExceeded(amount: string, limit: string | undefined) {
	const walletAmountNumber = parseFloat(amount);
	const limitNubmer = parseFloat(limit ?? '');
	return !isNaN(walletAmountNumber) && !isNaN(limitNubmer) && walletAmountNumber > limitNubmer;
}

export const orderTypeStoreDictionary = makeDynamicTranslations({
	all: tDynamic('Все'),
	manual_correct: tDynamic('Ручная корректировка'),
	cash_collect_courier: tDynamic('Прием денег'),
	cash_collect_store: tDynamic('Передача денег'),
	cash_hand_over_courier: tDynamic('Передача денег'),
	order: undefined,
});

export const orderTypeCourierDictionary = makeDynamicTranslations({
	all: tDynamic('Все'),
	manual_correct: tDynamic('Ручная корректировка'),
	cash_collect_courier: tDynamic('Сдача денег'),
	cash_collect_store: undefined,
	cash_hand_over_courier: tDynamic('Выдача денег'),
	order: tDynamic('Передача заказа с наличными'),
});

export function isCasherOrder(
	body: FinanceWalletsRequests.RejectOrderParams
): body is FinanceWalletsRequests.RejectCasherOrderParams {
	return Boolean('orderId' in body && body.orderId);
}

export function isFinanceWalletStatusFinal(status: FinanceWallets.OrderStatus) {
	return ['failed', 'canceled', 'complete', 'auto_canceled'].includes(status);
}

export function formatDate(date: dayjs.ConfigType, tz: string) {
	return dayjs(date).tz(tz).format('L LT');
}

export type CashConfig = FinanceWallets.CashConfig;

export function useCashConfiguration(): CashConfig {
	const constants = useUserConstants();
	const configs = constants.configs?.cash;
	const isConfigsReady = Boolean(constants);
	const isIncassationFormExtended = Boolean(configs?.extended_incassation_form_enabled.enabled);
	const isHandOffDoubleConfirmation = Boolean(configs?.cash_hand_off_double_confirmation_enabled.enabled);
	const isHandOverDoubleConfirmation = Boolean(configs?.cash_hand_over_double_confirmation_enabled.enabled);
	const isDoubleConfirmation = isHandOffDoubleConfirmation || isHandOverDoubleConfirmation;
	const isHandOffEnabled = Boolean(configs?.cash_hand_off_enabled.enabled);
	const isHandOverEnabled = Boolean(configs?.cash_hand_over_enabled.enabled);
	// todo: LAVKABBLOGISTIC-1933, remove testing flag
	const isCashCorrectionEnabled =
		Boolean(configs?.cash_correct_enabled.enabled) && process.env.REACT_APP_MODE !== 'production';
	const isCashCorrectionCommentRequiredEnabled = Boolean(configs?.cash_correct_comment_require_enabled.enabled);
	const cashOnHandLimit = configs?.cash_on_hand_limit.value || undefined;
	const cashHandOverLimit = configs?.cash_hand_over_limit.value || undefined;
	const cashHandOffTimerDurationLimit = configs?.cash_hand_off_timer_duration.value || undefined;

	return {
		isConfigsReady,
		isIncassationFormExtended,
		isHandOffEnabled,
		isHandOffDoubleConfirmation,
		isHandOverEnabled,
		isHandOverDoubleConfirmation,
		isDoubleConfirmation,
		isCashCorrectionEnabled,
		isCashCorrectionCommentRequiredEnabled,
		cashOnHandLimit,
		cashHandOverLimit,
		cashHandOffTimerDurationLimit,
	};
}
