import { Skeleton } from 'antd';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import analyticStatsInfo from '~constants/analyticStatsInfo';

import { HealthContext } from '../../context';
import type { StatDetails } from '../../healthTypes';
import { useStyles } from './styles';

type Props = {
	stat: StatDetails;
	threshold?: number;
};

const getSign = (isPositive: boolean | null, statSign?: boolean): boolean | null =>
	typeof isPositive === 'boolean' && typeof statSign === 'boolean'
		? (isPositive && statSign) || (!isPositive && !statSign)
		: null;

const StatBlock = ({ stat }: Props) => {
	const { classes, cx } = useStyles();
	const { t } = useTranslation();
	const { loading, levelState } = useContext(HealthContext);

	const hasSign = typeof analyticStatsInfo[stat.key].sign === 'boolean' && typeof stat.sign === 'boolean';
	const isGoodTrend = getSign(stat.sign, analyticStatsInfo[stat.key].sign);

	const loader = <Skeleton className={classes.loader} paragraph={{ rows: 1 }} title={false} active />;

	return (
		<div
			data-test={`health stat block ${stat.title}`}
			className={cx(classes.statBlock, {
				[classes.metricInRed]: stat.isRed,
			})}
		>
			<span className={classes.statTitle}>{stat.title}</span>
			<div className={classes.statContent}>
				<div className={classes.statInfo}>
					{levelState.type === 'store' && !loading && stat.threshold ? (
						<span className={cx(classes.goalText, { [classes.goalMetricInRed]: stat.isRed })}>
							{t('Цель {{time}}', { time: stat.threshold })}
						</span>
					) : null}
					<span className={classes.statValue}>{loading ? loader : stat.value}</span>
				</div>
				{stat.diff ? (
					<div
						className={cx(classes.statDiff, {
							[classes.redText]: isGoodTrend === false,
							[classes.greenText]: isGoodTrend === true,
						})}
					>
						{!loading && hasSign ? <span className={classes.statSign}>{stat.sign ? '▲' : '▼'}</span> : null}
						<span>{loading ? null : stat.diff}</span>
					</div>
				) : null}
			</div>
		</div>
	);
};

export default StatBlock;
