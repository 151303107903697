import { Layout, Menu } from 'antd';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';

import { useCache } from '~cache/useCache';
import { tabletMaxWidth } from '~constants/breakPoints';
import { ReactComponent as ChevronDownRegular } from '~images/icons/chevron-down-regular.svg';
import { useMenuStore } from '~shared/components/Layout/useMenuStore';
import { Link } from '~shared/ui-kit/Link';
import Icon from '~shared/utils/icons';
import { useFullscreenMode } from '~zustand/metaInfo';
import { checkExp, checkPermit, useCheckPermit, useUser, useUserDomainConfigs } from '~zustand/userData';

import type { MenuItems } from './menuItems';
import { menuItemsAdmin, menuItemsCompany, menuItemsStore } from './menuItems';
import { MobileMenuButton } from './MobileMenuButton';
import { SiderFooter } from './SiderFooter';
import { useStyles } from './styles';

const { Item: MenuItem } = Menu;

const filterMenuItems = (items: MenuItems) => {
	return items.filter(
		(menuItem) =>
			checkPermit(...[menuItem.permitName].flat()) &&
			(!menuItem.exp || checkExp(menuItem.exp)) &&
			menuItem.condition !== false
	);
};

export function Sider() {
	const [t, i18n] = useTranslation();
	const location = useLocation();
	const isTabletOrMobile = useMediaQuery({
		query: `(max-width: ${tabletMaxWidth}px)`,
	});
	const menuContainerRef = useRef<HTMLDivElement | null>(null);
	const user = useUser();
	const isPermitOutOfStore = useCheckPermit('out_of_store');
	const fullScreenMode = useFullscreenMode();
	const domainConfigs = useUserDomainConfigs();

	const { collapsed, toggleCollapsed, open, setOpen } = useMenuStore();

	useEffect(() => {
		setOpen(!isTabletOrMobile);
	}, []);

	const [menuShadow, showMenuShadow] = useState<boolean>(false);
	const { cx, classes } = useStyles({
		collapsed,
		menuShadow,
		fullScreenMode,
	});
	const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
	const [openedKeys, setOpenedKeys] = useState<number[]>([0]);

	const toggleOpen = () => setOpen(!open);

	const isMobileTableauPage = location.pathname === '/mtableau';

	useLayoutEffect(() => {
		if (menuContainerRef.current) {
			showMenuShadow(menuContainerRef.current.scrollHeight > menuContainerRef.current.clientHeight);
		}
	}, [menuContainerRef.current?.scrollHeight, menuContainerRef.current?.clientHeight, open, openedKeys]);

	useEffect(() => {
		if (isMobileTableauPage || (isTabletOrMobile && open)) {
			setOpen(false);
		} else if (!isTabletOrMobile && !open) {
			setOpen(true);
		}
	}, [isTabletOrMobile, location.pathname]);

	useEffect(() => {
		if (!open && collapsed) {
			toggleCollapsed();
		}
	}, [open]);

	useEffect(() => {
		if (location.pathname === `/stores/${user.store_id}`) {
			setSelectedKeys([location.pathname]);
		} else {
			const parts = location.pathname.split('/');
			// Удаляем в конце id сущности
			// Не используем .at, чтобы для устаревших браузеров показалось меню с предложением обновиться
			if (parts[parts.length - 1]?.length === 44) {
				parts.pop();
			}
			if (parts[parts.length - 1] === 'edit') {
				parts.pop();
			}

			setSelectedKeys([parts.join('/')]);
		}
	}, [location.pathname, user.store_id]);

	const store_id = user.store_id ?? '';
	const cache = useCache({
		stores: store_id,
	});

	const showKitchen = Boolean(cache.stores[store_id]?.kitchen_assortment_id);
	const menu = useMemo(() => {
		const menu: {
			items: MenuItems;
			title: string;
			key: string;
		}[] = [];
		let storeMenuItems: MenuItems = [];
		let companyMenuItems: MenuItems = [];
		if (user.store_id) {
			storeMenuItems = menuItemsStore(user.store_id).filter((e) => {
				if (e.name === 'kitchen_menu') {
					return showKitchen;
				}
				return true;
			});
		}
		if (user.company_id) {
			companyMenuItems = menuItemsCompany(user.company_id);
		}

		const storeMenu = filterMenuItems(storeMenuItems);
		if (storeMenu.length) {
			menu.push({
				items: storeMenu,
				title: t('Мой склад'),
				key: 'store',
			});
		}
		if (isPermitOutOfStore && companyMenuItems.length) {
			menu.push({
				items: filterMenuItems(companyMenuItems),
				title: t('Моя компания'),
				key: 'company',
			});
		}
		if (isPermitOutOfStore && menuItemsAdmin.length) {
			menu.push({
				items: filterMenuItems(menuItemsAdmin({ account: domainConfigs.account })),
				title: t('Админка'),
				key: 'main',
			});
		}
		return menu;
	}, [user.store_id, showKitchen, i18n.language]);

	const menuWithChild = () => {
		return (
			<Menu mode="inline" selectedKeys={selectedKeys} className={classes.mainMenu}>
				{menu.flatMap((menuGroup, index) => {
					const isOpened = menu.length > 1 ? openedKeys.includes(index) : true;

					if (menuGroup.items.some((item) => item.path === location.pathname) && !isOpened) {
						setOpenedKeys([index]);
					}

					const renderedItems = menuGroup.items.map((menuItem) => (
						<MenuItem
							className={classes.menuButton}
							data-test={`${menuGroup.key ? `${menuGroup.key} ` : ''}${menuItem.name} button`}
							key={menuItem.path}
							title={menuItem.text}
						>
							<Link
								className={classes.menuItem}
								to={menuItem.path}
								onClick={() => isTabletOrMobile && setOpen(false)}
								metrikaVars={{ menu_group: menuGroup.key, menu_item: menuItem.name, text: menuItem.text }}
							>
								<Icon component={menuItem.icon} style={{ fontSize: 16 }} />
								{!collapsed && menuItem.text}
							</Link>
						</MenuItem>
					));

					const visibleItems = isOpened ? renderedItems : [];
					if (menu.length > 1 && menuGroup.items.length) {
						return [
							<MenuItem
								key={menuGroup.key}
								data-test={`menu group ${menuGroup.key}`}
								className={classes.menuSeparator}
								onClick={() =>
									isOpened
										? setOpenedKeys(openedKeys.filter((key) => key !== index))
										: setOpenedKeys([...openedKeys, index])
								}
								title={menuGroup.title}
							>
								{!collapsed && menuGroup.title}
								<Icon component={ChevronDownRegular} style={{ transform: isOpened ? 'rotate(180deg)' : 'none' }} />
							</MenuItem>,
							...visibleItems,
						];
					} else {
						return renderedItems;
					}
				})}
			</Menu>
		);
	};

	return (
		<Layout.Sider
			width={260}
			className={cx(classes.sider, {
				mobileTableauSider: isMobileTableauPage,
			})}
			style={{
				insetInlineStart: (isMobileTableauPage ? isMobileTableauPage && open : !isTabletOrMobile) || open ? 0 : -260,
			}}
			trigger={null}
			collapsible
			collapsed={collapsed}
		>
			<MobileMenuButton
				open={open}
				toggleOpen={toggleOpen}
				isTabletOrMobile={isTabletOrMobile}
				isMobileTableau={isMobileTableauPage}
			/>
			<div className={classes.menuContainer} ref={menuContainerRef}>
				{menuWithChild()}
			</div>
			<SiderFooter collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
		</Layout.Sider>
	);
}
