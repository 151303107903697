import { notification } from '@lavka/ui-kit';
import { Button, Col, Row } from 'antd';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import api from '~/api';
import { useCache } from '~cache/useCache';
import { getDefaultValues, getField } from '~constants/dataPageFields/fieldHelper';
import type { SchetTaskHandlerType } from '~constants/dataPageFields/schetTaskFields';
import {
	schetTaskCreateCalcSalaries,
	schetTaskCreateCheckValid,
	schetTaskCreateSyncProducts,
} from '~constants/dataPageFields/schetTaskFields';
import type * as FieldTypes from '~constants/dataPageFields/types';
import DataForm from '~shared/components/DataForm';
import DataFormControls from '~shared/components/DataForm/DataFormControls';
import SchetsSchedule from '~shared/components/Fields/SchetsSchedule';
import { Field } from '~shared/components/Forms';
import Title from '~shared/components/Title';
import useEditMode from '~shared/hooks/useEditMode';
import useLoadData from '~shared/hooks/useLoadData';
import useRerender from '~shared/hooks/useRerender';
import { useSetTitle } from '~shared/hooks/useSetTitle';
import useUuid from '~shared/hooks/useUuid';
import { useStyles as useCommonStyles } from '~styles/common';
import type { Schets } from '~types/schets';
import { useCheckExp, useCheckPermit, useUser } from '~zustand/userData';

import ApproveChanges from './ApproveChanges';
import NeedApproveAlert from './NeedApproveAlert';
import type { ActionModalType } from './SchetActionModal';
import { SchetActionModal } from './SchetActionModal';

interface Props {
	addMode?: boolean;
	editMode?: boolean;
	baseUrl: string;
	baseFields: FieldTypes.SectionConfig[];
	handlerFieldOptions: SchetTaskHandlerType[];
	isStore?: boolean;
}

export const SchetsDataPage = memo(
	({ isStore, addMode, editMode, baseUrl, baseFields, handlerFieldOptions }: Props) => {
		const [t] = useTranslation();
		const { cx, classes: commonClasses } = useCommonStyles();
		const [sending, toggleSending] = useState(false);
		const externalId = useUuid([editMode]);
		const navigate = useNavigate();
		useEditMode(addMode || editMode);

		const { rerenderKey, updateRerenderKey } = useRerender();

		const [actionModalType, setActionModalType] = useState<ActionModalType | null>(null);

		const user = useUser();
		const isExpCheckValidEatToday = useCheckExp('exp_check_valid_eat_today');
		const isPermitSchetsSave = useCheckPermit('schets_save');
		const isPermitSchetsApprove = useCheckPermit('schets_approve');
		const isPermitSchetsStartPause = useCheckPermit('schets_start_pause');
		const isPermitSchetsForceRun = useCheckPermit('schets_force_run');
		const isPermitSchetsDelete = useCheckPermit('schets_delete');
		const cache = useCache({
			stores: user.store_id,
		});

		const params = useParams<{ schet_task_id: string }>();

		const schetTaskId = params.schet_task_id!;

		const { data: schetData, loading } = useLoadData(
			() => api.schets.load({ schet_task_id: schetTaskId }, { strict: true }),
			[schetTaskId, editMode, rerenderKey],
			addMode
		);

		const form = useForm<Schets.Schet | Schets.SchetTaskCreate>({
			defaultValues: getDefaultValues(baseFields),
			values: schetData?.result as Schets.Schet,
		});

		const { handleSubmit, control, resetField } = form;

		const handler = useWatch({ control, name: 'handler' });
		const schedule_draft = useWatch({ control, name: 'schedule_draft' });

		useEffect(() => {
			if (addMode) {
				resetField('kwargs', {});
			}
		}, [addMode, handler]);

		const fields = useMemo(() => {
			let allFields = baseFields;

			switch (handler) {
				case 'create_check_valid':
					allFields = [...allFields, ...schetTaskCreateCheckValid];
					break;
				case 'sync_products':
					allFields = [...allFields, ...schetTaskCreateSyncProducts];
					break;
				case 'calc_salaries':
					allFields = [...allFields, ...schetTaskCreateCalcSalaries];
					break;
			}

			const scheduleField = getField(allFields, t('Расписание'), 'schedule') as FieldTypes.CustomField | undefined;

			if (scheduleField) {
				scheduleField.element = (
					<Field
						name="schedule"
						key="schedule"
						label={t('Время начала периода и интервал')}
						editMode={editMode}
						addMode={addMode}
						params={{
							timezone: addMode ? cache.stores[user.store_id || '']?.tz : schetData?.result.tz,
						}}
						additionalContent={
							schedule_draft && (
								<Row>
									<Col span={24}>
										<NeedApproveAlert scheduleDraft={schedule_draft} timezone={schetData?.result.tz} />
									</Col>
								</Row>
							)
						}
						component={SchetsSchedule}
					/>
				);
			}

			const handlerField = getField(allFields, t('Основное'), 'handler') as FieldTypes.SelectField | undefined;

			if (handlerField) {
				handlerField.canEdit = addMode;
				handlerField.options = isStore
					? handlerFieldOptions.filter((task) => {
							return !['sync_products', 'update_vat', 'sync_price_lists'].includes(task);
						})
					: handlerFieldOptions;
			}

			const modeField = getField(allFields, t('Дополнительно'), 'kwargs.mode') as FieldTypes.SelectField | undefined;

			if (modeField) {
				// @ts-expect-error
				modeField.options = modeField.options.filter((mode) => {
					return mode !== 'eatToday2markdown' || isExpCheckValidEatToday;
				});
			}

			return allFields;
		}, [baseFields, handler, schedule_draft, editMode, addMode, handlerFieldOptions, schetData, cache.stores, user]);

		useCache({
			userExecutors: schetData?.result.approved_by,
		});

		const sendForm = useCallback(
			async (values: Schets.Schet | Schets.SchetTaskCreate) => {
				toggleSending(true);
				try {
					const dataObject = { ...values } as Schets.SchetTaskCreate;
					if (addMode) {
						dataObject.external_id = externalId;
					} else {
						dataObject.schet_task_id = schetTaskId;
					}

					// подстраиваемся под схему из yaml
					if (dataObject.handler === 'create_writeoff') {
						dataObject.kwargs = { order_type: 'writeoff' };
					}
					const { data } = await api.schets.save(dataObject);

					navigate(`${baseUrl}/${data.result.schet_task_id}`);
					notification.success({
						message: t('Задание сохранено'),
					});
				} catch {
					notification.error({
						message: t('Произошла ошибка'),
					});
				} finally {
					toggleSending(false);
				}
			},
			[schetTaskId]
		);

		let title = '';

		if (addMode) {
			title = t('Добавление задания');
		} else if (editMode && !addMode) {
			title = t('Редактирование задания');
		} else if (!editMode && !addMode) {
			title = t('Задание');
		}

		useSetTitle(title);

		return (
			<>
				<SchetActionModal
					modalType={actionModalType}
					setModalType={setActionModalType}
					schetTaskId={schetTaskId}
					setSchetUpdate={updateRerenderKey}
				/>
				<DataFormControls
					title={{
						edit: t('Редактирование задания'),
						add: t('Добавление задания'),
						view: (
							<Title
								title={t('Задание')}
								copyLinkTwo={schetData?.result.schet_task_id}
								successTextTwo={t('ID задания скопирован')}
								copyTooltipText={t('Скопировать ID задания')}
							/>
						),
					}}
					editMode={editMode}
					addMode={addMode}
					inProgress={sending}
					disabled={sending}
					enterEditModeLink={!addMode && isPermitSchetsSave ? `${baseUrl}/edit/${schetTaskId}` : undefined}
					quitEditModeLink={isPermitSchetsSave ? (addMode ? baseUrl : `${baseUrl}/${schetTaskId}`) : undefined}
					submit={handleSubmit(sendForm)}
					saveBtnTitle={t('Сохранить задание')}
					testSelectors={{
						back: 'schet task data page back button',
						exitEdit: 'schet task data page cancelEdit button',
						enterEdit: 'schet task data page edit button',
						save: 'schet task data page save button',
					}}
					additionalButtons={[
						{
							type: 'component',
							component: <ApproveChanges schetTaskId={schetTaskId} setSchetUpdate={updateRerenderKey} />,
							condition: !!(
								isPermitSchetsApprove &&
								schetData?.result.created_by !== user.user_id &&
								schetData?.result.schedule_draft
							),
						},
					]}
					dotsOptions={[
						{
							key: 'schet-start',
							component: (
								<Button
									className={commonClasses.dropdownBtn}
									onClick={() => setActionModalType('start')}
									data-test="schet start btn"
								>
									{t('Запустить по расписанию')}
								</Button>
							),
							condition: !!(
								!addMode &&
								!editMode &&
								isPermitSchetsStartPause &&
								schetData?.result.status === 'paused' &&
								schetData?.result.schedule
							),
						},
						{
							key: 'schet-start',
							component: (
								<Button
									className={commonClasses.dropdownBtn}
									onClick={() => setActionModalType('force_run')}
									data-test="schet force start btn"
								>
									{t('Запустить не дожидаясь расписания')}
								</Button>
							),
							condition: !!(
								!addMode &&
								!editMode &&
								isPermitSchetsForceRun &&
								schetData?.result.status === 'pending' &&
								schetData?.result.schedule
							),
						},
						{
							key: 'schet-pause',
							component: (
								<Button
									className={commonClasses.dropdownBtn}
									onClick={() => setActionModalType('pause')}
									data-test="schet pause btn"
								>
									{t('Отключить')}
								</Button>
							),
							condition: !addMode && !editMode && isPermitSchetsStartPause && schetData?.result.status === 'pending',
						},
						{
							key: 'schet-delete',
							component: (
								<Button
									className={cx(commonClasses.dropdownBtn, commonClasses.dropdownBtnDanger)}
									onClick={() => setActionModalType('delete')}
									data-test="schet delete btn"
								>
									{t('Удалить')}
								</Button>
							),
							condition: !addMode && !editMode && isPermitSchetsDelete && schetData?.result.status === 'paused',
						},
					]}
				>
					<DataForm
						onSubmit={sendForm}
						editMode={editMode}
						loading={loading}
						addMode={addMode}
						formFields={fields}
						dataTest={`schet task data page form ${editMode ? 'edit' : 'view'}`}
						hookForm={form}
					/>
				</DataFormControls>
			</>
		);
	}
);
