import { notification } from '@lavka/ui-kit';
import FileSaver from 'file-saver';
import yaml from 'js-yaml';

import api from '~/api';
import type { Zones } from '~types/zones';

export const eZonesColorDictionary: Record<number, string> = {
	1: '#1E98FF',
	2: '#595959',
	3: '#722ED1',
	4: '#EB2F96',
	5: '#2F54EB',
};

export const deliveryTypeColorDictionary: Record<Zones.Zone['delivery_type'], string> = {
	foot: '#41c604',
	foot_external: '#fda50a',
	foot_night: '#ffdd02',
	rover: '#1E98FF',
	car: '#595959',
	car_night: '#696969',
	yandex_taxi: '#722ED1',
	yandex_taxi_remote: '#EB2F96',
	yandex_taxi_night: '#2F54EB',
};

export const printerTokenLoad = async (storeId: string) => {
	const { data } = await api.stores.printerTokenLoad({
		store_id: storeId,
	});
	const config = {
		client: {
			event_url: '/',
			url: '/api/print-client',
			store_id: storeId,
			token: data.result,
		},
	};
	const dump = yaml.dump(config, { lineWidth: 400 });
	const blob = new Blob([dump], {
		type: 'text/plain;charset=utf-8',
	});
	FileSaver.saveAs(blob, 'printer.yml');
	notification.success({
		message: 'Конфиг принтера загружен',
	});
};

export const changedUsers = ({
	initialIds,
	allowed,
	disallowed,
}: {
	initialIds?: string[];
	allowed?: string[];
	disallowed?: string[];
}) => {
	const addedUsers = allowed?.filter((userId) => !initialIds?.includes(userId));
	const deletedUsers = disallowed?.filter((userId) => initialIds?.includes(userId));
	return { addedUsers, deletedUsers };
};
