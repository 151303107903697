import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider, UiKitTheme } from '@lavka/ui-kit';
import { ConfigProvider, theme as antTheme } from 'antd';
import { enableMapSet } from 'immer';
import type { FC } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { RouterProvider } from 'react-router-dom';
import { YMInitializer } from 'react-yandex-metrika';

import { apiLoadProperties } from '~cache/apiLoadProperties';
import { initCache } from '~cache/cache';
import { useLibTranslations } from '~i18n/hooks';
import EmptyTableState from '~shared/components/EmptyState/EmptyTableState';
import { useAsyncEffect } from '~shared/hooks/useAsyncEffect';
import Compose from '~shared/utils/compose';
import { isTvClientMode } from '~shared/utils/isTvClientMode';
import { getToken } from '~shared/utils/localStorage';
import { login } from '~shared/utils/loginFunctions';
import GlobalStyles from '~styles/GlobalStyles';
import { useUserDomainConfigs } from '~zustand/userData';
import { useUserTheme } from '~zustand/userSettings';

import { initMultiSelect } from '../multiSelect/initMultiSelect';
import { router } from '../router';
import Theme from './Theme';

// Отключаем SSR ошибки в консоли
// https://github.com/emotion-js/emotion/issues/1105#issuecomment-557726922
const emotionCache = createCache({
	key: 'css',
	nonce: window.__NONCE__,
});
emotionCache.compat = true;

const antdCspConfig = { nonce: window.__NONCE__ };

enableMapSet();
initCache(apiLoadProperties);
initMultiSelect(apiLoadProperties);

const App: FC = () => {
	const [t, i18n] = useTranslation();
	const lang = i18n.language;
	const theme = useUserTheme();
	const domainConfigs = useUserDomainConfigs();

	useAsyncEffect(async () => {
		// Пытаемся залогиниться из локалсторейджа
		await login({ token: getToken() });
	}, []);

	const { antdLocale } = useLibTranslations();

	// При первом рендере i18n еще не знает язык, что вызывает ререндер всего дерева.
	if (!lang || !antdLocale) {
		return null;
	}
	const metrikaOptions = {
		clickmap: true,
		trackLinks: true,
		accurateTrackBounce: true,
		webvisor: !isTvClientMode(),
	};

	return (
		<Compose
			components={[
				(children) => <CacheProvider value={emotionCache}>{children}</CacheProvider>,
				(children) => (
					<ConfigProvider
						csp={antdCspConfig}
						locale={antdLocale}
						direction={i18n.dir()}
						renderEmpty={(type?: string) =>
							type === 'Table' ? <EmptyTableState title={t('Нет данных')} /> : undefined
						}
						theme={{
							algorithm: theme === 'dark' ? antTheme.darkAlgorithm : antTheme.defaultAlgorithm,
							token: {
								fontFamily: 'YS Text',
							},
						}}
					>
						{children}
					</ConfigProvider>
				),
				(children) => <Theme>{children}</Theme>,
				(children) => <HelmetProvider>{children}</HelmetProvider>,
				(children) => (
					<ThemeProvider theme={UiKitTheme.Default} dark={theme === 'dark'}>
						{children}
					</ThemeProvider>
				),
			]}
		>
			{domainConfigs.yandex_metrika_id && process.env.NODE_ENV === 'production' && (
				<YMInitializer accounts={[domainConfigs.yandex_metrika_id]} options={metrikaOptions} version="2" />
			)}
			<GlobalStyles />
			<RouterProvider router={router} />
		</Compose>
	);
};

export default App;
