import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useUserDomainConfigs } from '~zustand/userData';

let options: {
	language: string;
	script: HTMLScriptElement;
} | null = null;

export function useYandexMapsScript(onLoad?: () => void, onUnload?: () => void) {
	const [, i18n] = useTranslation();
	const domainConfigs = useUserDomainConfigs();

	useEffect(() => {
		if (options?.language === i18n.language) {
			onLoad?.();
			return;
		}

		onUnload?.();

		if (options?.script) {
			document.body.removeChild(options.script);
		}

		const url = new URL('https://api-maps.yandex.ru/2.1/');
		url.searchParams.set('lang', i18n.language);
		url.searchParams.set('coordorder', 'longlat');
		url.searchParams.set('apikey', domainConfigs.yandex_maps_key ?? '');
		url.searchParams.set('csp', 'true');

		const script = document.createElement('script');

		script.src = url.toString();
		script.async = true;
		script.onload = onLoad ?? null;
		script.crossOrigin = 'anonymous';

		document.body.appendChild(script);

		options = {
			language: i18n.language,
			script,
		};
	}, [i18n.language, onLoad, onUnload]);
}
