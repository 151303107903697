import { PageHeaderButton } from '@lavka/ui-kit';
import dayjs from 'dayjs';
import { memo, useEffect, useMemo, useState } from 'react';

import api from '~/api';
import { dateFormat } from '~constants/date';
import useLoadData from '~shared/hooks/useLoadData';
import { CalendarRegularIcon } from '~shared/ui-kit/icons';
import { getSchetsData, useSchetsData } from '~zustand/schetsData';
import { useUser, useUserConstants } from '~zustand/userData';

import { defaultSearchData } from '../../../components/StoresPage/StoresDataPage/Events/constants';
import type { PreparedEvent } from '../../../components/StoresPage/StoresDataPage/Events/types';
import {
	getDaysOfWeek,
	prepareCheckProjectsData,
	prepareSchetsData,
} from '../../../components/StoresPage/StoresDataPage/Events/utils';
import type { SearchDataType } from '../DatePicker/types';
import ModalComponent from '../ModalComponent';
import { EventsCalendarContext } from './context';
import { ModalHeader } from './ModalHeader';
import ScheduleByDay from './ScheduleByDay';
import { useStyles } from './styles';

const EventsCalendar = memo(() => {
	const { classes } = useStyles();
	const [openSchedule, toggleOpenSchedule] = useState<boolean>(false);
	const { store_id } = useUser();
	const constants = useUserConstants();
	const [searchData, setSearchData] = useState<SearchDataType>(defaultSearchData);
	const timezone = constants.store?.tz ?? '';
	const startWeekFromSunday = dayjs().localeData().firstDayOfWeek() === 0;

	const schets = useSchetsData();

	useEffect(() => {
		if (store_id && !schets && openSchedule) {
			getSchetsData({ store_id });
		}
	}, [store_id, openSchedule]);

	const activeCheckProjects = useLoadData(
		() =>
			api.checkProjects.active_stores_projects({
				_fields: ['title', 'schedule'],
			}),
		[store_id, openSchedule],
		!openSchedule
	);

	const storeEventsDataByWeek = useMemo(() => {
		const daysOfWeek = getDaysOfWeek(searchData.local_date);
		const storeEventsDataWithWeekDays: Record<string, PreparedEvent[]> = {};

		daysOfWeek.forEach((day) => {
			storeEventsDataWithWeekDays[day.format(dateFormat)] = [];
		});

		const startOfWeek = daysOfWeek[0].startOf('week'); // начало недели
		const endOfWeek = daysOfWeek[0].endOf('week'); // конец недели
		const checkProjects = activeCheckProjects.data?.result ?? [];
		const filteredSchets = (schets ?? []).filter(
			(schet) =>
				(schet.handler === 'create_check_valid' || schet.handler === 'create_writeoff') &&
				['writeoff', 'check_valid_regular', 'check_valid_short', 'visual_control', 'writeoff_prepare_day'].includes(
					schet.kwargs.order_type
				) &&
				schet.status !== 'paused'
		);

		prepareSchetsData({
			schets: filteredSchets,
			eventsData: storeEventsDataWithWeekDays,
			endOfWeek,
			timezone,
		});

		prepareCheckProjectsData({
			checkProjects,
			startOfWeek,
			endOfWeek,
			eventsData: storeEventsDataWithWeekDays,
			timezone,
		});

		return storeEventsDataWithWeekDays;
	}, [searchData.local_date, activeCheckProjects.data?.result.length, schets?.length]);

	const handleCancel = () => {
		toggleOpenSchedule(false);
		setSearchData(defaultSearchData);
	};

	const eventsCalendarContextValue = useMemo(
		() => ({
			searchData,
			setSearchData,
			timezone,
			startWeekFromSunday,
			storeEventsDataByWeek,
			openSchedule,
		}),
		[searchData, setSearchData, timezone, startWeekFromSunday, storeEventsDataByWeek, openSchedule]
	);
	return (
		<>
			<PageHeaderButton
				variant="icon"
				view="header-accent"
				onClick={() => toggleOpenSchedule(!openSchedule)}
				data-test="event-calendar-button"
				iconLeft={<CalendarRegularIcon width={20} height={20} />}
			/>
			<EventsCalendarContext.Provider value={eventsCalendarContextValue}>
				<ModalComponent
					open={openSchedule}
					title={<ModalHeader storeId={store_id} toggleOpen={toggleOpenSchedule} />}
					footer={null}
					wrapClassName={classes.calendarContainer}
					bodyStyle={{ blockSize: '98%', padding: 0 }}
					style={{ insetBlockStart: 0, insetInline: '200% 0' }}
					onCancel={handleCancel}
				>
					<ScheduleByDay />
				</ModalComponent>
			</EventsCalendarContext.Provider>
		</>
	);
});

export default EventsCalendar;
