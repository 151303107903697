import { gray } from '~styles/colors';
import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	settingRow: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBlockEnd: 16,
		'&:last-child': {
			marginBlockEnd: 0,
		},
	},
	settingsDivider: {
		backgroundColor: gray[2],
		blockSize: 2,
		marginBlock: '15px',
		marginInline: '0',
	},
	logoutBtn: {
		inlineSize: '100%',
	},
	avatar: {
		cursor: 'pointer',
	},
	popover: {
		minInlineSize: 272,
		backgroundColor: theme.bgColor.main,
		boxShadow: theme.popoverShadow,
		borderRadius: 8,
		'& .ant-popover-arrow': {
			display: 'none !important',
		},
		'& .ant-popover-title': {
			border: 'none',
		},
		paddingBlockStart: 0,
		'& .ant-popover-inner': {
			backgroundColor: 'inherit',
		},
	},
	settingsTitle: {
		fontSize: '16px',
		marginBlockEnd: 16,
		color: theme.text.primary,
	},
	settingLabel: {
		margin: '0',
		marginInlineEnd: '40px',
		fontSize: '14px',
		flexShrink: 0,
		color: theme.text.primary,
	},
	userData: {
		marginBlockEnd: 4,
		'& p': {
			margin: 0,
			color: theme.text.secondary,
		},
	},
}));
