import { mobileMaxWidth } from '~constants/breakPoints';
import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	statsBlock: {
		display: 'grid',
		gridTemplateColumns: 'repeat(2, 1fr)',
		boxShadow: theme.statBlocksShadow,
		borderRadius: 8,
		border: `0.5px solid ${theme.border.metricsTale}`,

		[`@media (min-width:${mobileMaxWidth}px)`]: {
			gridTemplateColumns: 'repeat(auto-fit, minmax(127px, 1fr))',
		},
		['@media (min-width:1280px)']: {
			gridTemplateColumns: 'repeat(9, minmax(100px, 1fr))',
		},
	},
}));
