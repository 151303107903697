import { notification } from '@lavka/ui-kit';
import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import api from '~/api';
import TableHeader from '~shared/components/TableHeader';
import { useAsyncEffect } from '~shared/hooks/useAsyncEffect';
import { useSetTitle } from '~shared/hooks/useSetTitle';
import useSubscribe from '~shared/hooks/useSubscribe';
import uuid from '~shared/utils/uuid';
import type { Suptools } from '~types/suptools';
import { useUser } from '~zustand/userData';

import type { StashResponse } from './Block';
import { Block } from './Block';
import TomatoSpinner from './Card/Elements/TomatoSpinner';
import { SupportSearchContext } from './context';
import { StartScreen } from './StartScreen';

type BlockType = {
	key: string;
	text: string;
	runRequest: boolean;
};

const initRequest = [
	{
		key: uuid(),
		text: 'wms product b68ca8c7d6fa4e158eee078ad37d47dc000200020000',
		runRequest: false,
	},
];

export function SupportSearch() {
	const [searchParams] = useSearchParams();
	const [handlers, setHandlers] = useState<Suptools.SearchHandler[] | null>(null);
	const query = searchParams.get('query');
	const [showStartScreen, setShowStartScreen] = useState(!query);

	const [blocks, setBlocks] = useState<BlockType[]>(() =>
		query
			? [
					{
						key: uuid(),
						text: String(query),
						runRequest: true,
					},
				]
			: initRequest
	);

	useAsyncEffect(async () => {
		try {
			const { data } = await api.supTools.searchHandlers();
			setHandlers(data.result);
		} catch {
			notification.error({ message: 'Не удалось получить список хэндлеров' });
		}
	}, []);

	const [stashResponses, setStashResponses] = useState<Record<string, StashResponse>>({});
	const user = useUser();

	const pageTitle = '🍅  Помидорка';
	useSetTitle(pageTitle);

	useSubscribe(
		{
			key: ['user', 'suptools', user.user_id],
			cb: async (data) => {
				if (data) {
					const stashId = data.data?.[0]?.stash_id;
					try {
						const stash = await api.stashes.load({ stash_id: stashId });
						setStashResponses((stashes) => ({ ...stashes, [stashId]: stash.data.result }));
					} catch {
						notification.error({ message: 'Не удалось выгрузить результаты запроса, попробуйте еще раз' });
					}
				}
			},
			unSub: false,
			single: true,
			name: 'suptools',
		},
		[user.user_id]
	);

	const addBlock = useCallback((key: string, text: string, runRequest: boolean) => {
		const newKey = uuid();

		setBlocks((oldBlocks) => {
			const currentBlockIndex = oldBlocks.findIndex((e) => e.key === key);

			return [
				...oldBlocks.slice(0, currentBlockIndex + 1),
				{
					key: newKey,
					text,
					runRequest,
				},
				...oldBlocks.slice(currentBlockIndex + 1),
			];
		});

		setTimeout(() => {
			document.querySelector(`[data-test="block ${newKey}"]`)?.scrollIntoView({ behavior: 'smooth' });
		}, 500);
	}, []);

	const removeBlock = useCallback((key: string) => {
		setBlocks((oldBlocks) => {
			const currentBlockIndex = oldBlocks.findIndex((e) => e.key === key);

			if (oldBlocks.length === 1) {
				document.getElementById('root')!.innerHTML = `
				<iframe width="100%" height="${
					document.documentElement.clientHeight - 5
				}px" src="https://www.youtube.com/embed/LbOve_UZZ54?autoplay=1&t=11" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`;
			}

			return [...oldBlocks.slice(0, currentBlockIndex), ...oldBlocks.slice(currentBlockIndex + 1)];
		});
	}, []);

	const contextValue = useMemo(
		() => ({
			addBlock,
			removeBlock,
			responses: stashResponses,
			handlers,
		}),
		[addBlock, removeBlock, stashResponses, handlers]
	);

	return (
		<>
			<TableHeader title={pageTitle} />
			{showStartScreen && <StartScreen />}
			{!handlers ? (
				<TomatoSpinner />
			) : (
				<SupportSearchContext.Provider value={contextValue}>
					<div onClick={() => setShowStartScreen(false)} onKeyDown={() => setShowStartScreen(false)}>
						{blocks.map((block) => (
							<Block key={block.key} blockKey={block.key} text={block.text} runRequest={block.runRequest} />
						))}
					</div>
				</SupportSearchContext.Provider>
			)}
		</>
	);
}
