/* eslint-disable max-lines */

import { blue, red } from '@ant-design/colors';

import { makeStyles } from '~styles/theme';

import { iconOrange } from './colors';

export const useStyles = makeStyles()((theme) => ({
	title: {
		marginBlockEnd: '0 !important',
		color: theme.uiKit.semantic.textOnError + '!important',
		fontSize: '16px !important',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		lineHeight: 'normal',
		marginInlineEnd: 'auto',
	},
	editModeTitle: {
		color: theme.text.white + '!important',
	},
	modalControls: {
		display: 'flex',
		marginBlockEnd: 12,
		alignItems: 'center',
	},
	modalRadioButtons: {
		marginInlineStart: 'auto',
	},
	modalAlert: {
		marginInlineEnd: 'auto',
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		gap: 8,
		inlineSize: '100%',
	},
	container: {
		paddingBlock: '0',
		paddingInline: '12px',
	},
	layout: {
		paddingBlock: '0',
		paddingInline: '12px',
		blockSize: '100%',
		background: 'transparent',
	},
	linkButton: {
		background: 'transparent',
		border: 'none',
		outline: 'none',
		padding: 0,
		color: blue[5],
		cursor: 'pointer',
		transition: 'color 0.3s',
		'&:hover': {
			color: blue[4],
		},
		'&.disabled': {
			'&:hover': {
				color: 'inherit',
			},
		},
	},
	dropdownContainer: {
		paddingBlock: '12px',
		paddingInline: '0',
		backgroundColor: theme.bgColor.main,
		boxShadow: theme.shadow,
	},
	dropdownItem: {
		cursor: 'pointer',
		display: 'block',
		paddingBlock: '6px',
		paddingInline: '15px',
		color: theme.text.primary,
		'&:last-of-type': {
			marginBlockEnd: 0,
		},
		'&:hover': {
			backgroundColor: theme.bgColor.field.option.hover,
			transitionDuration: '.2',
			color: theme.text.selected,
		},
		'& .ant-upload': {
			inlineSize: '100%',
			'& [role="button"]': {
				inlineSize: '100%',
				display: 'block',
			},
		},
	},
	divider: {
		blockSize: 2,
		marginBlock: '16px',
		marginInline: '0',
	},
	btnIconBordered: {
		inlineSize: 32,
		blockSize: 32,
		display: 'inline-block',
		alignItems: 'center',
		justifyContent: 'center',
		padding: 0,
		borderColor: theme.border.secondary,
		//transition: 'color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)!important',
		'& .ant-btn': {
			padding: 0,
			inlineSize: 32,
			blockSize: 32,
		},
		'&.active .anticon': {
			color: theme.bgColor.icon.active,
		},
	},
	btnIcon: {
		paddingBlock: '0',
		paddingInline: '!important',
		inlineSize: 32,
		blockSize: 32,
		border: 'none',
		color: theme.text.primary,
		borderRadius: 4,
		backgroundColor: 'transparent !important',
		boxShadow: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		'& .anticon': {
			lineHeight: 0,
		},
		'&:hover:not(.ant-btn[disabled])': {
			transitionDuration: '.3s',
			color: `${theme.text.primary}!important`,
			backgroundColor: `${theme.border.primary}!important`,

			'&.mobileMenuIcon': {
				backgroundColor: 'transparent!important',
			},
		},
	},
	btnIconDisabled: {
		color: theme.text.disabled,
		cursor: 'not-allowed',
		'&:hover': {
			color: theme.text.disabled,
		},
		'& .ant-btn:not(.ant-btn-link[disabled], .ant-btn-primary)[disabled]': {
			inlineSize: 32,
			blockSize: 32,
			padding: 0,
			border: 'none',
			background: 'none',
		},
	},
	btnIconDanger: {
		color: `${red[5]}!important`,
		borderColor: `${red[5]}!important`,
		'&:hover': {
			color: `${red[4]}!important`,
			borderColor: `${red[4]}!important`,
		},
		'&:active': {
			color: `${red[4]}!important`,
			borderColor: `${red[4]}!important`,
		},
		'&:focus': {
			color: `${red[4]}!important`,
			borderColor: `${red[4]}!important`,
		},
	},
	dropdownBtn: {
		border: 'none',
		marginBlockEnd: 8,
		textAlign: 'start',
		justifyContent: 'start',
		color: theme.text.primary,
		inlineSize: '100%',
		boxShadow: 'none',
		'&:last-child': {
			marginBlockEnd: 0,
		},
		'&:hover': {
			color: `${theme.text.primary}!important`,
			transitionDuration: '.3s',
			backgroundColor: `${theme.text.menuItem.hover}!important`,
		},
	},
	dropdownBtnDisabled: {
		color: `${theme.text.disabled}!important`,
		cursor: 'not-allowed',
		backgroundColor: 'transparent!important',
		'&:hover': {
			color: `${theme.text.disabled}!important`,
		},
	},
	dropdownBtnDanger: {
		color: `${red[5]}!important`,
		'&:hover': {
			color: `${red[5]}!important`,
		},
	},
	printContainer: {
		'*': {
			color: 'black !important',
		},
		padding: '15mm',
		paddingBlockStart: '10mm',
		'& .ant-table-container': {
			borderColor: '#262626 !important',
			borderRadius: '0 !important',
			'& th': {
				borderRadius: '0 !important',
			},
			'& .ant-card': {
				blockSize: 'auto',
			},
			'& table, th, td': {
				borderColor: '#262626 !important',
			},
			'& .ant-table-cell': {
				paddingBlock: '3px',
				paddingInline: '16px',
				blockSize: 25,
				fontSize: 10,
			},
			'& .ant-table-row-expand-icon': {
				display: 'none',
			},
		},
	},
	listNumber: {
		cursor: 'pointer',
		opacity: '.8',
		marginInlineStart: 5,
		display: 'flex',
		alignItems: 'center',
		'&:hover': {
			transitionDuration: '.3s',
			opacity: 1,
		},
	},
	multipleBlock: {
		display: 'flex',
	},
	blockList: {
		paddingInlineStart: 0,
		margin: 0,
		fontWeight: 500,
		listStyle: 'none',
	},
	iconOrangeClock: {
		paddingBlock: '0',
		paddingInline: '3px',
		'& svg': {
			borderRadius: 30,
			fill: iconOrange,
		},
	},
	supportChatModal: {
		'.ant-modal-body': {
			blockSize: '70vh',
		},
	},
	scrollable: {
		overflow: 'auto',
		scrollbarColor: 'rgba(255, 255, 255, 0.15) rgba(0, 0, 0, 0)',
		'::-webkit-scrollbar': {
			backgroundColor: 'rgba(0, 0, 0, 0)',
			inlineSize: 8,
			blockSize: 8,
			zIndex: 999999,
		},
		'::-webkit-scrollbar-track': {
			backgroundColor: 'rgba(0, 0, 0, 0)',
		},
		'::-webkit-scrollbar-thumb': {
			backgroundColor: 'rgba(255, 255, 255, 0.15)',
			borderRadius: 8,
		},
		'::-webkit-scrollbar-button': {
			display: 'none',
		},
		':hover::-webkit-scrollbar-thumb': {
			backgroundColor: 'rgba(255,255,255,0.2)',
		},
		'::-webkit-scrollbar-thumb:hover': {
			backgroundColor: 'rgba(255,255,255,0.3)',
		},
	},
	copyButton: {
		display: 'inline-flex',
		blockSize: 20,
		inlineSize: 20,
		border: 'none',
		boxShadow: 'none',
		svg: {
			marginBlockEnd: -3,
			blockSize: 20,
			inlineSize: 20,
		},
	},
	idRow: {
		'>:not(:last-child)': {
			marginInlineEnd: 4,
		},
	},
	externalLinkButton: {
		display: 'inline-flex',
	},
}));
