import type { LabelProps } from '@lavka/ui-kit';
import type { FunctionComponent, SVGProps } from 'react';

import { ReactComponent as GapGoIcon } from '~images/merchant_icons/gap-go.svg';
import { ReactComponent as YangoMarket } from '~images/merchant_icons/yango-market.svg';
import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';

export const statusTextColorDictionary: Record<keyof typeof orderStatuses, Exclude<LabelProps['color'], undefined>> = {
	delivering: 'blue',
	customer_no_show: 'orange',
	place_confirmed: 'gray',
	pickup: 'gray',
	awaiting_card_payment: 'orange',
	arrived_to_customer: 'blue',
	failed: 'red',
	canceled: 'red',
	complete: 'green',
	processing: 'purple',
	not_started: 'orange',
	waitingAssignment: 'orange',
	approving: 'orange',
};

export const orderStatuses = makeDynamicTranslations({
	delivering: tDynamic('В пути'),
	customer_no_show: tDynamic('Клиент не ответил'),
	place_confirmed: tDynamic('Получен Лавкой'),
	pickup: tDynamic('Самовывоз'),
	awaiting_card_payment: tDynamic('Ждём оплату'),
	arrived_to_customer: tDynamic('Передаётся'),
	failed: tDynamic('Невозможно выполнить'),
	canceled: tDynamic('Отменен'),
	complete: tDynamic('Назначен, ожидает вывоза'),
	waitingAssignment: tDynamic('Собран, ожидает назначения'),
	processing: tDynamic('Собирается'),
	approving: tDynamic('Подтверждается'),
	not_started: tDynamic('Ожидает сборки'),
});

export const merchantIcons: Record<
	string,
	FunctionComponent<SVGProps<SVGSVGElement>> & { title?: string | undefined }
> = {
	supermarketaz_yango: YangoMarket,
	supermarketaz: GapGoIcon,
};

export const merchantNames: Record<string, string> = {
	supermarketaz_yango: 'Yango Market',
	supermarketaz: 'GapGo',
};
