import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	header: {
		marginBlockEnd: '5px',
		fontSize: '15.6px',
		fontWeight: 'bold',
		color: '#262626',
	},
	rowData: {
		fontWeight: 'bold',
		paddingInlineStart: '4px',
	},
	assignButton: {
		cursor: 'pointer',
		border: 'none',
		paddingBlock: '2px',
		paddingInline: '4px',
		borderRadius: '8px',
		backgroundColor: theme.bgColor.activeSelect,
		display: 'inline',
		'& svg': {
			paddingBlockStart: '2px',
		},
	},
	assignButtonTitle: {
		marginInlineStart: '4px',
	},
	balloonHeader: {
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		'&.ant-btn:not(.ant-btn-link, .ant-btn-primary)': {
			backgroundColor: 'transparent',
		},
		border: 'none',
		padding: 'unset',
		fontSize: 15.6,
		fontWeight: 'bold',
		svg: {
			marginInlineStart: 4,
		},
	},
}));
